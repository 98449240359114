/**
 * application constants
 * 
 */

 const Constants = {
  Application: {
      // // SITE_URL: 'https://pub.kgtopg.com',
      // SITE_URL: 'http://localhost:3000',
      // // PLATFORM_END_URL: 'https://admin.kgtopg.com',
       //PLATFORM_END_URL: 'http://192.168.29.224/platform',
      // // PARTNER_END_URL: 'https://partner.kgtopg.com',
       //PARTNER_END_URL: 'http://192.168.29.224/partner',
      // // NODEJS_END_URL: 'https://api.kgtopg.com',
       //NODEJS_END_URL: 'http://192.168.29.224/nodeserver',
      // // PUSTAK_URL: 'https://books.kgtopg.com',
       //PUSTAK_URL: 'http://192.168.29.224/pustak',
      // // PUSTAK_BOOKS_URL: 'https://books.kgtopg.com/static/books',
       //PUSTAK_BOOKS_URL: 'http://192.168.29.224/pustak/static/books',
       //SPRING_API_URL: 'http://192.168.29.224:8080/examerspring',
      // // SPRING_API_URL: 'https://api2.classess.com',
      // // PLATFORM_END_URL: 'http://192.168.10.117:8002',
      // // PARTNER_END_URL: 'http://192.168.10.117:8004',
      // // TEMP_END_URL: 'http://192.168.10.117:8003',
      // // TEMP_END_URL1: 'http://192.168.10.117:8001',

      SITE_URL: 'https://pub.kgtopg.com',
      PLATFORM_END_URL: 'https://admin.kgtopg.com',
      PARTNER_END_URL: 'https://partner.kgtopg.com',
      NODEJS_END_URL: 'https://api.kgtopg.com',
      PUSTAK_URL: 'https://books.kgtopg.com',
      PUSTAK_BOOKS_URL: 'https://books.kgtopg.com/static/books',
      SPRING_API_URL: 'https://api2.classess.com',

      FIREBASE: {
          // API_KEY: 'AIzaSyBTCkmhlLRvtmztSh9yIl_rgfXvieYg3eY',
          API_KEY: 'AIzaSyDF89eJzw66BMvAbJi9M7BrtMpU7uIsAP4',
          // AUTH_DOMAIN: 'kgtopg-profiles.firebaseapp.com',
          AUTH_DOMAIN: 'classess-501e6.firebaseapp.com',
          // DATABASE_URL: 'https://<DATABASE_NAME>.firebaseio.com',
          // STORAGE_BUCKET: 'kgtopg-profiles.appspot.com'
          STORAGE_BUCKET: 'classess-501e6.appspot.com'
      },
      ROLE_CONT_PROV: "CONTENT_PROVIDER",
      ROLE_EBOOK_PUB: "EBOOK_PROVIDER",
      ROLE_CONT_EBOOK_PUB:"CONTENT_PROVIDER AND PUBLISHER",
      QUIZ: {
          ID_QTYPE_MULTIPLE_CHOICE: 201,
          ID_QTYPE_MULTIPLE_SELECT: 202,
          ID_QTYPE_TRUE_FALSE: 203,
          ID_QTYPE_TEXT_TYPE: 204,
          ID_QTYPE_PICTURE_CHOICE: 205,
          ID_QTYPE_MATCH_FLW: 206
      },
      LOGIN_TYPE_BOOKPUB: 301,
      LOGIN_TYPE_CONTPROV: 302,
  }
}


export default Constants;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Divider, FormHelperText, Grid, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ArrowBack, Label, Refresh } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import firebase from "firebase";
import Constants from '../../resource/Constants';
import FileUploader from "react-firebase-file-uploader";
import { listBoardGet } from '../../redux/actions/boardDetailsAction';
import { listInstGet } from '../../redux/actions/InstituteDetailsAction';
import { listSubGet } from '../../redux/actions/subjectsAction';
import { listStdGet } from '../../redux/actions/standardAction';
import { listChpGet, listChpGet2 } from '../../redux/actions/chapterAction';
import { listTopGet } from '../../redux/actions/topicAction';
import { Alert, AlertTitle } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookie from 'js-cookie';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import API from '../../http/http'
import PARTNERAPI from '../../http/httppartner'
import PUBLISHERAPI from '../../http/publisher'
import bookUpload from "../../assets/bookUpload.jpg"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Autocomplete from "@material-ui/lab/Autocomplete";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography5 from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Grid2 from '@mui/material/Grid';
const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const options = [
    'Take a photo or a screenshot of each page',
    'Order each page by their index or number (Example 001.jpg, 002.jpg etc..)',
    'Make sure each photo is of only JPG format',
    'Take care in count of pages, it should be even so as to allow front cover and back cover to appear independently',
    'Compress all files under a Zip without creating a folder',
    'Select the Zip file when choosing the book',
    // 'Hangouts Call',
    // 'Luna',
    // 'Oberon',
    // 'Phobos',
    // 'Pyxis',
    // 'Sedna',
    // 'Titania',
    // 'Triton',
    // 'Umbriel',
];

const useStyles2 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

function generate(element) {
    return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function Alert2(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function InteractiveList() {
    const classes = useStyles();
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    return (
        <div style={{
            padding: 20
        }}>
            <Typography variant="body2" className={classes.title}>
                Please follow the instructions below
            </Typography>
            <div className={classes.demo}>
                <List dense={dense}>
                    {options.map((op, index) => (

                        <ListItem>
                            <ListItemText
                                primary={(index > -1) ? 'Step ' + (index + 1) : null}
                                secondary={op}
                            />
                        </ListItem>
                    )

                    )}

                </List>
            </div>
        </div>
    )
}

function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Book Upload Instructions</DialogTitle>
            <DialogContent dividers>
                {/* <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((option) => (
                        <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup> */}
                <InteractiveList />

            </DialogContent>
            <DialogActions>
                {/* <Button autoFocus onClick={handleCancel} color="primary">
                    Dismiss
                </Button> */}
                <Button onClick={handleOk} color="primary">
                    Got It!
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

const useStyles3 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));

function ConfirmationDialog() {
    const classes = useStyles3();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('Click to view upload instructions');

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>

                <InfoOutlinedIcon />
                <List component="div" role="list">
                    {/* <ListItem button divider disabled role="listitem">
                    <ListItemText primary="Instructions" />
                </ListItem> */}
                    <ListItem
                        button
                        divider
                        aria-haspopup="true"
                        aria-controls="ringtone-menu"
                        aria-label="Ebook Upload Instructions"
                        onClick={handleClickListItem}
                        role="listitem"
                    >
                        <ListItemText primary="" secondary={value} />
                    </ListItem>
                    {/* <ListItem button divider disabled role="listitem">
                    <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                </ListItem> */}
                    <ConfirmationDialogRaw
                        classes={{
                            paper: classes.paper,
                        }}
                        id="ringtone-menu"
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        value={value}
                    />
                </List>
            </div>
        </div>
    );
}

const config = {
    apiKey: Constants.Application.FIREBASE.API_KEY,
    authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
    //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
    storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function TabPanel1(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

TabPanel1.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function a11yProps1(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        margin: theme.spacing(1),
        // minWidth: 350,
        // minHeight: 30,
        width: '90%',
        color: "#069122"

    },
    formControl1: {
        margin: theme.spacing(1),
        // minWidth: 350,
        width: '90%',
        minHeight: 30,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar: {
        marginRight: 24,
        justifyContent: "space-between"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "flex-end",
        alignSelf: "center",
    },
    button1: {
        margin: theme.spacing(1),
        justifyContent: "flex-end",
        justifyItems: "flex-end",
        justifySelf: "flex-end",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        alignContent: "flex-end",
    },
}));




const boardDetailsMock = [
    { institute_board_id: 10001, institute_board_name: "CBSE", institute_board_f_desc: "cbse board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10002, institute_board_name: "Andhra Pradesh State Board", institute_board_f_desc: "AP state board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10003, institute_board_name: "ICSE", institute_board_f_desc: "ICSE board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10004, institute_board_name: "Bihar State Board", institute_board_f_desc: "Bihar state board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10005, institute_board_name: "Cambridge International", institute_board_f_desc: "Cambridge International board", institute_board_status: 1, created_date: "2020-05-27" },
    { institute_board_id: 10006, institute_board_name: "Chhattisgarh State Board", institute_board_f_desc: "Chhattisgarh state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10007, institute_board_name: "Gujarat State Board", institute_board_f_desc: "Gujarat state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10008, institute_board_name: "International Baccalaureate Board", institute_board_f_desc: "International Baccalaureate board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10009, institute_board_name: "Jharkhand State Board", institute_board_f_desc: "Jharkhand state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10010, institute_board_name: "J&K State Board", institute_board_f_desc: "J&K state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10011, institute_board_name: "Karnataka State Board", institute_board_f_desc: "Karnataka state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10012, institute_board_name: "Kerala State Board", institute_board_f_desc: "Kerala state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10013, institute_board_name: "Madhya Pradesh State Board", institute_board_f_desc: "Madhya Pradesh state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10014, institute_board_name: "Maharashtra State Board", institute_board_f_desc: "Maharashtra state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10015, institute_board_name: "Punjab State Board", institute_board_f_desc: "Punjab state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10016, institute_board_name: "Rajasthan State Board", institute_board_f_desc: "Rajasthan state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10017, institute_board_name: "Tamil Nadu State Board", institute_board_f_desc: "Tamil Nadu state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10018, institute_board_name: "Telangana State Board", institute_board_f_desc: "Telangana state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10019, institute_board_name: "Uttarakhand State Board", institute_board_f_desc: "Uttarakhan state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10020, institute_board_name: "Uttar Pradesh State Board", institute_board_f_desc: "Uttar Pradesh state board", institute_board_status: 1, created_date: "2020-05-30" },
    { institute_board_id: 10021, institute_board_name: "West Bengal State Board", institute_board_f_desc: "West Bengal state board", institute_board_status: 1, created_date: "2020-05-30" },

]

const instituteMockData = [
    { inst_id: 1, inst_type: "School" },
    { inst_id: 2, inst_type: "College" },
    { inst_id: 3, inst_type: "University" },
    { inst_id: 4, inst_type: "Institute" },
]

const standardsMockData = [
    { stand_id: 1, stand_name: 1 },
    { stand_id: 2, stand_name: 2 },
    { stand_id: 3, stand_name: 3 },
    { stand_id: 4, stand_name: 4 },
    { stand_id: 5, stand_name: 5 },
    { stand_id: 6, stand_name: 6 },
    { stand_id: 7, stand_name: 7 },
    { stand_id: 8, stand_name: 8 },
    { stand_id: 8, stand_name: 9 },
    { stand_id: 9, stand_name: 9 },
    { stand_id: 10, stand_name: 10 },
    { stand_id: 11, stand_name: 11 },
    { stand_id: 12, stand_name: 12 },
]

const subjectsMockData = [
    { subj_id: 1, subj_name: "English" },
    { subj_id: 2, subj_name: "Maths" },
    { subj_id: 3, subj_name: "Social" },
    { subj_id: 4, subj_name: "Physics" },
    { subj_id: 5, subj_name: "Chemistry" },
    { subj_id: 6, subj_name: "Biology" },

]

const chaptersMockData = [
    { chap_id: 1, chap_name: "A Letter to God." },
    { chap_id: 2, chap_name: "Polynomials" },
    { chap_id: 3, chap_name: "Federalism" },
    { chap_id: 4, chap_name: " Light-Reflection and Refraction. " },
    { chap_id: 5, chap_name: " Acids, Bases and Salts" },
    { chap_id: 6, chap_name: "Life Processes" },

]

const TopicsMockData = [
    { topic_id: 1, topic_name: "How Do Organisms Reproduce" },
    { topic_id: 2, topic_name: "Our Environment" },
    { topic_id: 3, topic_name: " Sources Of Energy." },
    { topic_id: 4, topic_name: " Coordinate Geometry. " },
    { topic_id: 5, topic_name: " Acids, Bases and Salts" },
    { topic_id: 6, topic_name: "Metals and Non Metals." },


]


function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );

}

const useStyles1 = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    }
})



function SubjectsBox(props) {
    const [autoOpen, setAutoOpen] = React.useState(false)
    const [inputValue, setInputValue] = React.useState('')
    const [subName, setSubName] = useState('Subject')

    const { autoSubs, actvRow } = props

    const dispatch = useDispatch();

    const onSubjectChanged = (event, value) => {
        console.log('subject changed value ', value)

        if (value) {
            const subId = parseInt(value.id);
            props.setSubjectType(subId)
            setSubName(value.label)


            // get chapters of full subject
            dispatch(listChpGet2(subId, props.boardType, props.clsStd));
        } else {

            // setAutoOpen(false);

        }
    }

    const setSub = (sub) => {
        if (sub) {
            setSubName(sub.subject_name)
            props.setSubjectType(sub.subject_id)
            setInputValue(sub.subject_name)
        }
    }

    useEffect(() => {

        console.log('actvRow inside subjects box ', actvRow);
        if (actvRow) {
            setSub(actvRow)
        }
        return {

        }
    }, [])

    return (
        (autoSubs && autoSubs.length) ?
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={autoSubs}
                sx={{ width: '90%' }}
                style={{
                    width: '90%',
                    alignSelf: 'center',
                    margin: '0 auto',
                    // marginBottom: 20
                }}
                onOpen={() => {
                    // only open when in focus and inputValue is not empty
                    if (inputValue) {
                        setAutoOpen(true);
                    }
                }}
                onClose={() => setAutoOpen(false)}
                onInputChange={(e, value, reason) => {
                    // setSubName(value);
                    console.log('setting inputvalue ', value)
                    setInputValue(value);

                    // only open when inputValue is not empty after the user typed something
                    if (!value) {
                        setAutoOpen(false);
                    }
                }}
                inputValue={inputValue}
                getOptionLabel={(option) => option.label.toString()}
                onChange={onSubjectChanged}
                open={autoOpen}
                renderInput={(params) => <TextField {...params} label={subName} />}
            />
            :
            <></>
    );
}



export default function ContentMaking(props) {
    const classes = useStyles();
    const classes1 = useStyles1();

    // extract vars
    const { currBook } = props;

    console.log("currBook at upload screen", currBook)

    const [value, setValue] = React.useState(0);
    const [age, setAge] = React.useState('');
    const [showInputField, SetShowInputField] = useState(false)
    const [selectBoardDetails, setSelectedBoardDetails] = useState("")
    const [boardDetailsState, setBoardDetailsState] = useState(null);
    const [filenames, setFileNames] = useState([]);
    const [ebookDownloadUrls, setEbookDownloadUrls] = useState((currBook) ? currBook.ebook_location_url : "");
    const [ImageDownloadUrls, setImageDownloadUrls] = useState((currBook) ? currBook.ebook_image_url : "")
    const [progress, setProgress] = useState(0);
    const [progressBook, setProgressBook] = useState(0)

    //ebook upload details
    const [pubId, setPubId] = useState(1)
    const [bookName, setBookName] = useState("")
    const [InstValue, setInstValue] = useState((currBook) ? currBook.institute_type_id : "")
    const [boardValue, setBoardValue] = useState("")
    const [stdvalue, setStdValue] = useState("")
    const [course, setCourse] = useState("")
    const [subjectId, setSubjectId] = useState("")
    const [locUrl, setLocUrl] = useState("")
    const [imgUrl, setImgUrl] = useState("")
    const [noChap, setNoChap] = useState("")
    const [noLes, setNoLes] = useState("")
    const [noPgs, setNoPgs] = useState("")
    const [cat, setCat] = useState((currBook) ? currBook.category_id : 1)

    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0)
    const [instType, setInstType] = useState("")
    const [instTypeErr, setInstTypeErr] = useState(false)

    const [boardType, setBoardType] = useState((currBook) ? currBook.institute_board_id : "")
    const [boardTypeErr, setBoardTypeErr] = useState(false)

    const [subjectType, setSubjectType] = useState((currBook) ? currBook.subject_id : "")
    const [subjectTypeErr, setSubjectTypeErr] = useState(false)

    const [standardType, setStandardType] = useState((currBook) ? currBook.classroom_std : "")
    const [standardTypeErr, setStandardTypeErr] = useState(false)

    const [chap, setChap] = useState(1)
    const [les, setLes] = useState(1)
    const [lesErr, setLesErr] = useState(false)

    const [title, setTitle] = useState((currBook) ? currBook.ebook_name_text : "")
    const [titleErr, setTitleErr] = useState(false)
    const [titleHelp, setTitleHelp] = useState('')

    const [term, setTerm] = useState("")
    const [termErr, setTermErr] = useState(false)
    const [termHelp, setTermHelp] = useState('')

    const [video, setVideo] = useState("")
    const [videoErr, setVideoErr] = useState(false)
    const [videoHelp, setVideoHelp] = useState('')

    const [author, setAuthor] = useState((currBook) ? currBook.ebook_author_name : "")
    const [authorErr, setAuthorErr] = useState(false)
    const [authorHelp, setAuthorHelp] = useState('')

    const [bookPrice, setBookPrice] = useState((currBook) ? (currBook.ebook_price) ? currBook.ebook_price : 1 : 1)
    const [bookPErr, setBookPErr] = useState(false)
    const [bookPHelp, setBookPHelp] = useState('')

    const [snackOpen, setSnackOpen] = useState(false)
    const [errSnackOpen, setErrSnackOpen] = useState(false)

    const [selectedFile, setSelectedFile] = useState("")
    const [selectedFiles, setSelectedFiles] = useState([])
    const [submitProg, setSubmitProg] = useState(0)
    const [fileSelect, setFileSelect] = useState(false)

    const [insOpen, setInsOpen] = useState(false)
    const [agree, setAgree] = useState(false)

    const [selTerm, setSelTerm] = useState((currBook) ? currBook.term_number : 99)
    const [uploadMultiple, setUploadMultiple] = useState(false);
    const [pageNos, setPageNos] = useState([]);

    const handlePageNoChange = (e, index) => {
        const newPageNos = [...pageNos];
        newPageNos[index] = e.target.value;
        setPageNos(newPageNos);
    };

    const handleSelTermChange = (e) => {
        const { name, value } = e.target;
        console.log(`sel term ${value}`)

        setSelTerm(value)
    }

    const handleAgreeToggle = (e) => {
        console.log(e.target.checked)

        setAgree(e.target.checked)
    }

    const handleInsOpen = () => {
        setInsOpen(true)
    }

    const handleInsClose = () => {
        setInsOpen(false)
    }

    const handleSnackOpen = (open) => {
        setSnackOpen(open)
    }

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    const handleErrSnackOpen = (open) => {
        setErrSnackOpen(open)
    }

    const handleErrSnackClose = () => {
        setSnackOpen(false)
    }

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleTitleChange = (event) => {
        console.log(`new title ${event}`)
        const { name, value } = event.target
        setTitle(value)
    }

    const handleTermChange = (event) => {
        console.log(`new title ${event}`)
        const { name, value } = event.target
        setTerm(value)
    }

    const handleVideoChange = (event) => {
        console.log(`new video ${event}`)

        const { name, value } = event.target
        setVideo(value)
    }


    const handleInstChange = (event) => {
        console.log('called handleInstChange')
        console.log(`new institute type ${event}`)
        const name = event.target.name;
        const value = event.target.value;
        console.log("value at inst change", value)

        // setState({
        // ...state,
        // [name]: event.target.value,
        // });
        dispatch(listBoardGet(value))
        dispatch(listStdGet(value))
        setInstValue(value)

    }

    const handleBoardChange = (event) => {
        console.log('called handleBoardChange')
        console.log(`new board  ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        console.log(`board selected value ${value}`)
        console.log("value at inst ", InstValue)

        setBoardType(value)
        dispatch(listSubGet(value))

        dispatch(listStdGet(value))
    }

    const handleStdChange = (event) => {
        console.log('called handleStdChange')
        console.log(`new standard ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        setStandardType(value)

    }

    const handleSubChange = (event) => {
        console.log('called handleSubChange')
        console.log(`new subject ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        setSubjectType(value)

        // get chapters of full subject
        dispatch(listChpGet(value, boardType));
    }

    const handleChpChange = (event) => {
        console.log('called handleChpChange')
        console.log(`new chapter ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        setChap(value)
    }

    const handleAuthorChange = (event) => {
        console.log('called handleAuthorChange')


        const name = event.target.name;
        const value = event.target.value;
        setAuthor(value)
    }

    const handlePriceChange = (event) => {
        console.log('called handlePriceChange')


        const name = event.target.name;
        const value = event.target.value;
        setBookPrice(value)
    }

    const handleLessChange = (event) => {
        console.log('called handleTpcChange')
        console.log(`new topic ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        setLes(value)
    }

    const handlePagesChange = (event) => {
        console.log('called handleTpcChange')
        console.log(`new topic ${event}`)

        const name = event.target.name;
        const value = event.target.value;
        setNoPgs(value)
    }


    const BootstrapInput = withStyles((theme) => ({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            height: 30,
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            width: 310,
            padding: '10px 26px 10px 12px',
            placeholder: "Title",
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',

                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }))(InputBase);

    const showInput = () => {
        SetShowInputField(true)

    }

    const handleUploadStart = () => {
        setIsUploading(true)
        setProgress(0)
    }



    const handleProgressImage = progress => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }
    const handleProgressBook = progress => {
        setProgressBook((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }

    const handleUploadError = error => {
        setIsUploading(false)
    }


    // const handleChangeCheckBox = (event) => {
    //     setState({ ...state, [event.target.name]: event.target.checked });
    //   };

    const boardDetailsGet = useSelector(state => state.boardDetails)
    const { loading, boardDetails, error } = boardDetailsGet

    const instDetailsGet = useSelector(state => state.instDetails)
    const { loadingInst, instDetails, errorInst } = instDetailsGet

    const subDetailsGet = useSelector(state => state.subDetails)
    const { loadingSub, subDetails, errorSub } = subDetailsGet

    const stdDetailsGet = useSelector(state => state.stdDetails)
    const { loadingStd, stdDetails, errorStd } = stdDetailsGet;

    const chpDetailsGet = useSelector(state => state.chpDetails)
    const { loadingChap, chapDetails, errorChp } = chpDetailsGet

    const topDetailsGet = useSelector(state => state.topDetails)
    const { loadingTop, TopDetails, errorTop } = topDetailsGet

    console.log("chapterDetails", chpDetailsGet)
    console.log("TopicDetails", topDetailsGet)
    console.log("subject details", subDetails)

    /* called after render completed.. */
    useEffect(() => {

        console.log('useefffect')

        dispatch(listInstGet())

        if (currBook) {
            getBookSelections()
        }

        dispatch(listChpGet())
        dispatch(listTopGet())


        // const timer = setInterval(() => {
        //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        // }, 800)
        return () => {
            // clearInterval(timer);
            // 
        };
    }, []);

    const getBookSelections = async () => {
        const instType = currBook.institute_type_id;
        const boardType = currBook.institute_board_id;
        const subType = currBook.subject_id

        await new Promise((resolve, reject) => {
            dispatch(listBoardGet(instType))
            resolve()
        })

        await new Promise((resolve, reject) => {
            dispatch(listStdGet(instType))
            resolve()
        })

        await new Promise((resolve, reject) => {
            dispatch(listSubGet(boardType))
            resolve()
        })

    }

    const handleUploadEbookSuccess = async filename => {
        const downloadURL = await firebase
            .storage()
            .ref("ebook")
            .child(filename)
            .getDownloadURL();

        console.log("ebook download url", downloadURL)


        if (downloadURL) {
            setProgressBook(0)
        }
        // setFileNames(filename)
        setEbookDownloadUrls(downloadURL)
        // setUploadProgress(100)
        // setIsUploading(false)


    }


    const handleUploadImageSuccess = async filename => {
        const downloadUrl = await firebase
            .storage()
            .ref("ebook")
            .child(filename)
            .getDownloadURL()


        console.log("image download url", downloadUrl)
        setImageDownloadUrls(downloadUrl)
        if (downloadUrl) {
            setProgress(0)
        }
    }



    const handleBulkContentUpload = () => {
        console.log(`ebook url ${ebookDownloadUrls}`)

        if (!ebookDownloadUrls
            || !InstValue
            || !boardType
            || !subjectType
            || !standardType) {
            handleSnackOpen(true)
        } else {
            // send data

            const body = {
                institute_type_id: 10001,
                institute_board_id: 1001,
                classroom_std: 10,
                course: 'M.P.C',
                subject_id: 108,
                content_location: ebookDownloadUrls
            }

            const resp = PUBLISHERAPI.postSaveBulkContent(body)
            console.log(`response ${JSON.stringify(resp)}`)

        }
    }


    /****** SUBJECTS AUTOCOMPLETE */
    const autoSubs = []
    console.log(`subdetails data ${JSON.stringify(subDetails)}`)
    if (subDetails && subDetails.data
        && subDetails.data.subjects && subDetails.data.subjects.length) {
        subDetails.data.subjects.map(sub2 => {
            console.log('sub2 ', sub2)
            const sub = {
                label: sub2.subject_name, id: sub2.subject_id.toString()
            }

            autoSubs.push(sub)
        })
    }


    const handleSingleEbookUpload = async () => {
        console.log(`ebook url ${ebookDownloadUrls}`)
        console.log("ebook image url", ImageDownloadUrls)

        let proceed = true;

        let isMultiple = selectedFiles.length > 1;
        setUploadMultiple(isMultiple);

        // see if is single book and if all chapters values are not empty
        if (!isMultiple) {
            if (pageNos.some((value) => !value)) {
                console.log('Please fill in all page numbers');
                proceed = false;
                handleErrSnackOpen(true);
            } else {
                // Log the array of page numbers
                console.log(pageNos);
            }
        }

        // bypass above check
        proceed = true;

        if (proceed) {

            if (

                !InstValue
                || !boardType
                || !subjectType
                || !standardType
                || !title
                || !selTerm
                // || !author
                || !bookPrice
                // || (!selectedFile && !currBook) // if not edit and not selected file
                || ((!selectedFiles || !selectedFiles.length) && !currBook)

            ) {

                proceed = false;
                // show error labels
                if (!InstValue) {
                    setInstTypeErr(true)
                } else {
                    setInstTypeErr(false)
                }

                if (!boardType) {
                    setBoardTypeErr(true)
                } else {
                    setBoardTypeErr(true)
                }

                if (!subjectType) {
                    setSubjectTypeErr(true)
                } else {
                    setSubjectTypeErr(false)
                }

                if (!standardType) {
                    setStandardTypeErr(true)
                } else {
                    setStandardTypeErr(false)
                }

                if (!title) {
                    setTitleErr(true)
                } else {
                    setTitleErr(false)
                }

                // if (!author) {
                //     setAuthorErr(true)
                // } else {
                //     setAuthorErr(false)
                // }

                if (!selTerm) {
                    setTermErr(true)
                } else {
                    setTermErr(false)
                }

                if (!bookPrice) {
                    setBookPErr(true)
                } else {
                    setBookPErr(false)
                }


                handleErrSnackOpen(true)
                console.log("error ************************************************************")
            } else {


                // NOW ALL FIELDS VALID, TRY UPLOAD
                // send data
                console.log("saving =============================================================")

                try {

                    if (currBook) {

                    } else {


                    }

                    const body2 = {
                        publisher_id: pubId,
                        ebook_name_text: title,
                        institute_type_id: InstValue,
                        institute_board_id: boardType,
                        classroom_std: standardType,
                        course: standardType,
                        subject_id: subjectType,
                        number_chapters: chap,
                        number_lessons: les,
                        number_pages: 1,
                        ebook_location_url: ebookDownloadUrls,
                        ebook_image_url: ImageDownloadUrls,
                        term_number: selTerm,
                        ebook_author_name: author,
                        ebook_price: bookPrice,
                        category_id: cat,
                        // ebook_id:currBook.id
                        is_published: 1,
                        is_published_final: 1,
                        approved_by: pubId // ### NEED TO SEE ACCESS CONTROLS
                    }

                    const body = new FormData()
                    body.append('publisher_id', pubId)
                    body.append('ebook_name_text', title)
                    body.append('institute_type_id', InstValue)
                    body.append('institute_board_id', boardType)
                    body.append('classroom_std', standardType)
                    body.append('course', course)
                    body.append('subject_id', subjectType)
                    body.append('number_chapters', chap)
                    body.append('number_lessons', les)
                    body.append('number_pages', 1)
                    body.append('ebook_location_url', ebookDownloadUrls)
                    body.append('ebook_image_url', ImageDownloadUrls)

                    body.append('is_multiple', isMultiple);
                    // body.append('file', selectedFile)
                    // body.append('pdf_file', selectedFile)

                    if(isMultiple) {
                        // build the chapNumList key
                    }
                    for (let f = 0; f < selectedFiles.length; f++) {
                        body.append('pdf_file', selectedFiles[f])
                    }

                    let chapNumList = [];

                    // iterate chapters
                    for(let c=0; c < chapDetails.data.length; c++) {
                        let chap = chapDetails.data[c];
                        let chapObj = {
                            chapter_id: 0
                        }
                    }

                    body.append('term_number', selTerm)
                    body.append('ebook_author_name', author)
                    body.append('ebook_price', bookPrice)
                    body.append('category_id', cat);
                    if (currBook) {
                        body.append('book_id', currBook.id)
                        body.append('ebook_id', currBook.id)
                    }

                    setSubmitProg(true)

                    console.log(`json body ${JSON.stringify(body2)}`)
                    console.log(`formdata body ${body}`)
                    console.log(`ebook save request body ${JSON.stringify(body)}`)


                    let resp = null

                    if (currBook) {
                        if (selectedFiles) {
                            resp = await PUBLISHERAPI.postSaveSingleEbook(body, currBook.id); // sending form data
                            console.log(`response ebook single upload => ${JSON.stringify(resp)}`)
                        } else {
                            // JUST UPDATE DETAILS
                            // resp = await PUBLISHERAPI.postSaveSingleEbook2(body2, currBook.id)
                            // console.log(`response ebook single upload for book ${currBook.id} => ${JSON.stringify(resp)}`)
                        }

                    } else { // edit??
                        resp = await PUBLISHERAPI.postSaveSingleEbook(body, 0)
                        console.log(`response ebook single upload => ${JSON.stringify(resp)}`)
                    }

                    const book_id = resp.data.ebook_id
                    console.log("parameters", pubId, InstValue, boardType, subjectType, standardType, title, course, chap, noPgs, les, ebookDownloadUrls, ImageDownloadUrls)


                    // close drawer
                    if (resp) {
                        handleSnackOpen(true)
                        setSubmitProg(false)

                        setTimeout(function () {
                            props.onCloseDrawer(props.anchorType, false)
                        }, 2000)

                    }


                    // call ebooks list again
                    props.onReloadEbooks()
                } catch (e) {
                    console.log(`exception upload single e-book ${e.message}`)
                    handleErrSnackOpen(true)
                }

                // enable button
                setIsUploading(false)
                setProgress(false)
                setProgressBook(false)
                setSubmitProg(false)
            }


        }

    }

    useEffect(() => {

        setPublisher()
        return (
            <></>
        )
    }, [])

    function setPublisher() {
        const userId = Cookie.get('kgtopg.partner.user.id')
        setPubId(userId)

        console.log(`pubId is ${userId}`)
    }

    const handleInputChangeOld = (event) => {
        setProgressBook(true)
        setSelectedFile(event.target.files[0])
        if (event.target.files[0] && selectedFile != null) {
            setEbookDownloadUrls(true)
            setProgressBook(false)

        }


        console.log("selected file in handleInputChange", selectedFile, event.target.files[0])
    }

    const handleInputChange = (event) => {
        setProgressBook(true)
        const newFiles = event.target.files;
        let oldFiles = selectedFiles;
        // oldFiles.push()
        // setSelectedFile(event.target.files[0])

        let isMultiple = newFiles.length > 1;
        setUploadMultiple(isMultiple);
        setSelectedFiles(newFiles)
        if (event.target.files[0] && selectedFiles != null) {
            setEbookDownloadUrls(true)
            setProgressBook(false)

        }

        console.log("selected files in handleInputChange", selectedFiles, event.target.files)
    }

    return (
        <div
            style={{
                // width: 400
            }}>
            <Toolbar className={classes.toolbar}>

                <Typography component="h2" color="inherit" noWrap className={classes.title} style={{ fontFamily: "Poppins, Helvetica, sans-serif", }}>
                    Add New Book
                </Typography>


            </Toolbar>

            <Divider style={{ marginBottom: 0 }} />
            <TabPanel value={value} index={0}>

                <div style={{ justifyContent: "center", alignContent: "center", alignItems: "center", display: "block" }}>
                    {/* <ConfirmationDialog /> */}

                    <div>
                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 0, marginLeft: 10, marginRight: 20, width: "100%" }}>
                            <label style={{ fontFamily: "Poppins, Helvetica, sans-serif", cursor: 'pointer' }}>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        cursor: 'pointer'
                                    }}>
                                    <AddBoxIcon style={{
                                        color: 'steelblue'
                                    }} />
                                    <Typography
                                        variant='body1'
                                        style={{
                                            marginLeft: 10,
                                            color: "#464E5F",
                                        }}>
                                        Add Cover Image
                                    </Typography>
                                    <FileUploader
                                        hidden
                                        accept="image/*"
                                        storageRef={firebase.storage().ref('ebook')}
                                        onUploadStart={handleUploadStart}
                                        onUploadError={handleUploadError}
                                        onUploadSuccess={handleUploadImageSuccess}
                                        onProgress={handleProgressImage}
                                    />
                                </div>
                            </label>

                            <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: 20 }}>
                                {progress ?

                                    <CircularProgress />
                                    :
                                    <></>
                                }
                            </div>
                        </div>

                        {/* <Typography style={{ marginLeft: 15 }}>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",

                                }}
                            >
                                Select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    Image
                                </span>
                            </span>
                        </Typography> */}

                        <div style={{ position: 'relative', display: "flex", justifyContent: "flex-start", paddingLeft: 20 }}>
                            {ImageDownloadUrls &&
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            cursor: 'pointer',
                                            zIndex: 3
                                        }}>
                                        <label>
                                            <EditIcon />
                                            <FileUploader
                                                hidden
                                                accept="image/*"
                                                storageRef={firebase.storage().ref('ebook')}
                                                onUploadStart={handleUploadStart}
                                                onUploadError={handleUploadError}
                                                onUploadSuccess={handleUploadImageSuccess}
                                                onProgress={handleProgressImage}
                                            />

                                        </label>
                                    </div>
                                    <img src={ImageDownloadUrls} alt="preview Image" style={{ alignSelf: "flex-start", display: "flex", width: 100 }} />
                                    {/* {ImageDownloadUrls} */}
                                </>
                            }
                        </div>

                    </div>



                    <div style={{ marginBottom: 10 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginLeft: 10, marginRight: 20, }}>
                            <div>
                                <label style={{ fontFamily: "Poppins, Helvetica, sans-serif", cursor: 'pointer', textAlign: "center" }}

                                    onClick={() => {
                                        document.getElementById('ebookfile').click()
                                    }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            cursor: 'pointer'
                                        }}>
                                        <MenuBookIcon style={{
                                            color: 'steelblue'
                                        }} />
                                        <Typography
                                            variant='body1'
                                            style={{
                                                marginLeft: 10,
                                                color: "#464E5F",
                                            }}>
                                            Add PDF Book
                                        </Typography>
                                    </div>


                                    {/* <div
                                        style={{
                                            textAlign: 'left',
                                            marginTop: 10
                                        }}>
                                        <Alert severity="info">
                                            <AlertTitle>Note</AlertTitle>
                                            If you are uploading multiple books (chapters) —
                                            <strong>you have to name the files in chronological order!</strong>.
                                            For Example:
                                            <ul>
                                                <li>1.pdf (Foreword)</li>
                                                <li>2.pdf (Index)</li>
                                                <li>3.pdf (Chapter 1)</li>
                                            </ul>
                                        </Alert>
                                    </div> */}

                                    {/* <FileUploader
                                    hidden
                                    // accept="image/*"
                                    storageRef={firebase.storage().ref('ebook')}
                                    onUploadStart={handleUploadStart}
                                    onUploadError={handleUploadError}
                                    onUploadSuccess={handleUploadEbookSuccess}
                                    onProgress={handleProgressBook}
                                /> */}


                                </label>
                                <input accept="application/pdf" type="file" multiple="multiple" className="form-control" name="upload_file" onChange={handleInputChange} style={{ display: 'none' }} id="ebookfile" />
                            </div>

                            <div>
                                <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: 20 }}>
                                    {progressBook ?

                                        <CircularProgress />
                                        :
                                        <></>
                                    }
                                </div>

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {ebookDownloadUrls ?
                                        <CheckCircleOutlinedIcon style={{ color: "green", fontSize: 30 }} />
                                        // <img src={bookUpload} alt="preview Image" width="200px" style={{ alignSelf: "center", display: "flex" }} />
                                        : <></>
                                    }
                                </div>
                            </div>

                        </div>


                        {/* <Typography style={{ marginLeft: 15 }}>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#464E5F",
                                    marginLeft: 5,
                                    fontFamily: "Poppins, Helvetica, sans-serif",

                                }}
                            >
                                Select <span
                                    style={{
                                        color: "#464E5F",
                                        fontWeight: "600",
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                        fontSize: 12
                                    }}>
                                    PDF Book
                                </span>
                            </span>
                        </Typography> */}
                        {/* 
                        <Button variant="outlined" color="primary" onClick={handleInsOpen}>
                            Open Instructions
                        </Button> */}

                        {/* <InteractiveList /> */}


                    </div>


                    {/* <div
                        style={{

                        }}>

                        <Checkbox aria-label="Upload Multiple Chapters"
                            checked={uploadMultiple}
                            onChange={(event) => {
                                setUploadMultiple(!uploadMultiple);
                            }}
                            disabled
                        />Multiple Books (Chapters)

                    </div> */}


                    {instDetailsGet ?

                        loadingInst && instDetailsGet ?
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                width: '100%'
                            }}>
                                <FormControl variant="outlined"
                                    className={classes.formControl}
                                    style={{
                                        alignSelf: "center",
                                        // border: "1px solid green",
                                        width: "90%"
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-outlined-label"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                    >
                                        Select Institute type
                                    </InputLabel>

                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={InstValue}
                                        onChange={handleInstChange}
                                        label="Select Institute type"
                                        size="small"
                                        style={{
                                            fontSize: 13,
                                            color: "#464E5F",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            width: '100%'
                                        }}
                                        error={instTypeErr}
                                        helperText={'Help'}
                                        fullWidth
                                    >
                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>
                                    </Select>
                                    {/* <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please select <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Institute type
                                        </span>
                                    </span> */}

                                </FormControl>

                            </div>
                            :
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                width: '100%'
                            }}>

                                <FormControl variant="outlined" className={classes.formControl} style={{ alignSelf: "center" }} >
                                    <InputLabel id="demo-simple-select-outlined-label"
                                        style={{
                                            alignSelf: "center",
                                            // border: "1px solid green",
                                            width: "90%"
                                        }}
                                    >
                                        Select Institute type
                                    </InputLabel>

                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={InstValue}
                                        onChange={handleInstChange}
                                        error={instTypeErr}
                                        label="Select Institute type"
                                        size="small"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}

                                    >


                                        {loadingInst && instDetailsGet ?
                                            console.log("loading trueeee")
                                            :
                                            instDetails.length != 0 ?

                                                instDetails.data.map((list, index) => {
                                                    return (
                                                        <MenuItem key={index} value={list.institute_type_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_type_name}</MenuItem>

                                                    )
                                                })
                                                :

                                                <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>

                                        }


                                    </Select>

                                    {/* <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please select <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Institute type
                                        </span>
                                    </span> */}
                                </FormControl>

                            </div>



                        :
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            width: '100%'
                        }}>

                            <FormControl variant="outlined" className={classes.formControl} style={{ alignSelf: "center" }} >
                                <InputLabel id="demo-simple-select-outlined-label"
                                    style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                >
                                    Select Institute type
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={InstValue}
                                    onChange={handleInstChange}
                                    error={instTypeErr}
                                    label="Select Institute type"
                                    size="small"
                                    style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}

                                >
                                    <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Institutes</MenuItem>
                                </Select>
                                {/* <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Please select <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        Institute type
                                    </span>
                                </span> */}
                            </FormControl>

                        </div>
                    }








                    {boardDetailsGet && boardDetails && InstValue != null ?

                        loading && boardDetailsGet && boardDetails ?
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                    >
                                        Select Board/Uni
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={boardType}
                                        onChange={handleBoardChange}
                                        error={boardTypeErr}
                                        label="Select Board/Uni"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                    >

                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>



                                    </Select>
                                    {/* <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please select <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Board/Uni
                                        </span>
                                    </span> */}
                                </FormControl>
                            </div>

                            :
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                    >
                                        Select Board/Uni
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={boardType}
                                        onChange={handleBoardChange}
                                        error={boardTypeErr}
                                        label="Select Board/Uni"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                    >
                                        {loading ?// && boardDetailsGet && boardDetails && boardDetails.length != 0 ?
                                            console.log("loading trueeee")
                                            :
                                            boardDetails && boardDetails.data && boardDetails.data.length != 0 ?

                                                boardDetails.data.map((list, index) => {
                                                    return (
                                                        <MenuItem key={index} value={list.institute_board_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.institute_board_name}</MenuItem>

                                                    )
                                                })
                                                :

                                                <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                                        }


                                    </Select>
                                    {/* <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please select <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Board/Uni
                                        </span>
                                    </span> */}
                                </FormControl>
                            </div>
                        :
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel
                                    id="demo-simple-select-outlined-label"
                                    style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                >
                                    Select Board/Uni
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={boardType}
                                    onChange={handleBoardChange}
                                    error={boardTypeErr}
                                    label="Select Board/Uni"
                                    style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                >

                                    <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Boards </MenuItem>



                                </Select>
                                {/* <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Please select <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        Board/Uni
                                    </span>
                                </span> */}
                            </FormControl>
                        </div>
                    }




                    {
                        stdDetailsGet ?


                            loadingStd && stdDetailsGet ?
                                <div style={{ display: "flex", justifyContent: "center" }}>


                                </div>

                                :
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                        >
                                            Select Standard/Stream
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={standardType}
                                            onChange={handleStdChange}
                                            error={standardTypeErr}
                                            label="Standard/Stream"
                                            style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                        >
                                            {loadingStd && stdDetailsGet ?
                                                console.log("loading trueeee")
                                                :
                                                stdDetails && stdDetails.data && stdDetails.data.length != 0 ?

                                                    stdDetails.data.map((list, index) => {
                                                        return (
                                                            <MenuItem key={index} value={list.id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.standard}</MenuItem>

                                                        )
                                                    })
                                                    :

                                                    <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                                            }

                                        </Select>

                                        {/* <span
                                            style={{
                                                fontSize: 12,
                                                color: "#464E5F",
                                                marginLeft: 5,
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                            }}
                                        >
                                            Please select <span
                                                style={{
                                                    color: "#464E5F",
                                                    fontWeight: "600",
                                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                                    fontSize: 12
                                                }}>
                                                Standard/Stream
                                            </span>
                                        </span> */}
                                    </FormControl>

                                </div>

                            :

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                    >
                                        Select Standard/Stream
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={standardType}
                                        onChange={handleStdChange}
                                        error={standardTypeErr}
                                        label="Select Standard/Stream"
                                        style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}
                                    >

                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Standard / Streams</MenuItem>

                                    </Select>

                                    {/* <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please select <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Standard/Stream
                                        </span>
                                    </span> */}
                                </FormControl>

                            </div>

                    }






                    {subDetailsGet && subDetails && subDetails.data ?

                        loadingSub && subDetailsGet ?


                            <div style={{ display: "flex", justifyContent: "center" }} >
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Select Subject</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={subjectType}
                                        onChange={handleSubChange}
                                        error={subjectTypeErr}
                                        label="Select Subject"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >


                                        <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                                    </Select>

                                </FormControl>

                            </div>
                            :
                            <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 30 }} >


                                <SubjectsBox
                                    autoSubs={autoSubs}
                                    setSubjectType={setSubjectType}
                                    boardType={boardType}
                                    clsStd={standardType}
                                />


                                {/* 
                                
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Select Subject3</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={subjectType}
                                        onChange={handleSubChange}
                                        error={subjectTypeErr}
                                        label="Select Subject"
                                        style={{ fontSize: 13, color: "#464E5F" }}
                                    >

                                        {loadingSub && subDetailsGet ?
                                            console.log("loading trueeee")
                                            :
                                            subDetails.data.subjects.length != 0 ?

                                                subDetails.data.subjects.map((list, index) => {
                                                    return (
                                                        <MenuItem key={index} value={list.subject_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subject_name}</MenuItem>

                                                    )
                                                })
                                                :

                                                <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                                        }

                                    </Select>

                                </FormControl>
                                */}
                            </div>

                        :








                        <div style={{ display: "flex", justifyContent: "center" }} >
                            {/* 
                            <SubjectsBox
                                autoSubs={autoSubs}
                                setSubjectType={setSubjectType} />

                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Select Subject2</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={subjectType}
                                    onChange={handleSubChange}
                                    error={subjectTypeErr}
                                    label="Select Subject"
                                    style={{ fontSize: 13, color: "#464E5F" }}
                                >


                                    <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>No Subjects</MenuItem>

                                </Select>

                            </FormControl> */}

                        </div>







                    }





                    {loadingSub && subDetailsGet ?

                        <div style={{ display: "flex", justifyContent: "center" }} >
                            {/* <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Select Subject4</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={subjectType}
                                    onChange={handleSubChange}
                                    error={subjectTypeErr}
                                    label="Select Subject"
                                    style={{ fontSize: 13, color: "#464E5F" }}
                                >

                                    {loadingSub && subDetailsGet ?
                                        console.log("loading trueeee")
                                        :
                                        subDetails.data.subjects.length != 0 ?

                                            subDetails.data.subjects.map((list, index) => {
                                                return (
                                                    <MenuItem key={index} value={list.subject_id} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>{list.subject_name}</MenuItem>

                                                )
                                            })
                                            :

                                            <MenuItem style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Please Select Institute Type</MenuItem>

                                    }

                                </Select>
                                
                            </FormControl> */}

                        </div>
                        :
                        <></>
                    }





                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10, width: '100%' }}>
                        <FormControl style={{ width: '90%' }}>
                            <TextField id="outlined-basic" label="Enter Title" variant="outlined"
                                style={{
                                    // width: 350,
                                    fontFamily: "Poppins, Helvetica, sans-serif",

                                }}
                                classes={{ root: classes1.customTextField }}
                                defaultValue={title}
                                onChange={handleTitleChange}
                                error={titleErr}
                                helperText={titleHelp}
                                fullWidth
                            />
                            <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Please enter title example: <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        10 Maths (CBSE)
                                    </span>
                                </span>
                            </Typography>

                        </FormControl>

                    </div>



                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10, width: '100%' }}>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 0, marginBottom: 0, marginRight: 5, width: '100%' }}>
                            <FormControl variant="outlined" className={classes.formControl} >
                                <InputLabel id="demo-simple-select-outlined-label" style={{ fontSize: 13, color: "#464E5F" }}>Select Term / Semester</InputLabel>
                                {/* <TextField
                                    id="outlined-basic"
                                    label="Enter Term Num"
                                    variant="outlined"

                                    style={{
                                        width: 350,
                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                    }}
                                    classes={{ root: classes1.customTextField }}
                                    defaultValue={term}
                                    onChange={handleTermChange}
                                    error={termErr}
                                    helperText={termHelp}
                                /> */}

                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selTerm}
                                    onChange={handleSelTermChange}
                                    fullWidth
                                    label="Select Term / Semester"
                                    style={{ fontSize: 13, color: "#464E5F" }}
                                >
                                    <MenuItem key={99} value={99} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Full Year (Individual)</MenuItem>
                                    <MenuItem key={1} value={1} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Term / Semester 1</MenuItem>
                                    <MenuItem key={2} value={2} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Term / Semester 2</MenuItem>
                                    <MenuItem key={3} value={3} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Term / Semester 3</MenuItem>
                                    <MenuItem key={4} value={4} style={{ fontSize: 13, color: "#464E5F", fontFamily: "Poppins, Helvetica, sans-serif", }}>Term / Semester 4</MenuItem>

                                </Select>


                                {/* <Typography>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Please enter <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Term number
                                        </span>
                                    </span>
                                </Typography> */}

                            </FormControl>


                        </div>



                    </div>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10, marginRight: 5, width: '100%' }}>
                        <FormControl style={{ width: '90%' }}>
                            <TextField
                                placeholder="Optional"
                                id="outlined-basic" label="Author Name (Optional)" variant="outlined"
                                style={{
                                    // width: 350,
                                    fontFamily: "Poppins, Helvetica, sans-serif",

                                }}
                                defaultValue={author}
                                onChange={handleAuthorChange}
                                error={authorErr}
                                helperText={authorHelp}
                                fullWidth
                            />

                            {/* 
                            <BootstrapInput id="demo-customized-textbox" placeholder="Enter video URL"
                                defaultValue={video}
                                onChange={handleVideoChange} />

                             */}
                            {/* <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Enter <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        Author Name
                                    </span>
                                </span>
                            </Typography> */}


                        </FormControl>
                    </div>

                    {/* <div style={{ display: "flex", justifyContent: "center" }}> */}

                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Assign responsibility</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={check} onChange={handleChangeCheckBox} name="gilad" />}
                                        label="Do you want publish this book for free?"
                                    />
                                  
                                </FormGroup>
                                <FormHelperText>Be careful</FormHelperText>
                            </FormControl>
                        </div> */}

                    {/* <div style={{ display: "flex", justifyContent: "center", marginTop: 0, marginBottom: 20, marginLeft: 0 }}>
                            <FormControl>
                                <TextField id="outlined-basic" label="Book Price" variant="outlined"
                                    style={{
                                        width: 350,
                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                    }}
                                    defaultValue={bookPrice}
                                    onChange={handlePriceChange}
                                    error={bookPErr}
                                    helperText={bookPHelp}
                                />
                                <Typography>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Enter <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Book Price
                                        </span>
                                    </span>
                                </Typography>
                            </FormControl>
                        </div> */}


                    {/* </div> */}
                    {/* 
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 20, marginRight: 5 }}>
                            <FormControl>
                                <TextField id="outlined-basic" label="Enter no of Chapters" variant="outlined"
                                    style={{
                                        width: 170,
                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                    }}
                                    defaultValue={video}
                                    onChange={handleChpChange}
                                    error={videoErr}
                                    helperText={videoHelp}
                                />

                                <Typography>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Number of  <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Chapters
                                        </span>
                                    </span>
                                </Typography>


                            </FormControl>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 20, marginLeft: 5 }}>
                            <FormControl>
                                <TextField id="outlined-basic" label="Enter no of Lessons" variant="outlined"
                                    style={{
                                        width: 170,
                                        fontFamily: "Poppins, Helvetica, sans-serif",

                                    }}
                                    error={lesErr}
                                    defaultValue={video}
                                    onChange={handleLessChange}
                                />

                                <Typography>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            color: "#464E5F",
                                            marginLeft: 5,
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                        }}
                                    >
                                        Number of <span
                                            style={{
                                                color: "#464E5F",
                                                fontWeight: "600",
                                                fontFamily: "Poppins, Helvetica, sans-serif",
                                                fontSize: 12
                                            }}>
                                            Lessons
                                        </span>
                                    </span>
                                </Typography>


                            </FormControl>
                        </div>

                    </div>
 */}


                    {/* <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10 }}>
                        <FormControl>
                            <TextField id="outlined-basic" label="Enter no of Pages" variant="outlined"
                                style={{
                                    width: 350,
                                    fontFamily: "Poppins, Helvetica, sans-serif",

                                }}
                                defaultValue={video}
                                onChange={handlePagesChange}
                            />

                            <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Number of  <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        Pages
                                    </span>
                                </span>
                            </Typography>


                        </FormControl>
                    </div> */}


                    {/* 
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10 }}>
                        <FormControl>
                            <TextField id="outlined-basic" label="Enter Cover Image URL" variant="outlined"
                                style={{
                                    width: 350,
                                    fontFamily: "Poppins, Helvetica, sans-serif",

                                }}
                                defaultValue={video}
                                onChange={handleVideoChange}
                            />

                            <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Please enter <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        Cover Image URL
                                    </span>
                                </span>
                            </Typography>


                        </FormControl>
                    </div>

 */}






                    {/* <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 20 }}>
                        <FormControl>
                            <TextField id="outlined-basic" label="Enter Video URL" variant="outlined"
                                style={{
                                    width: 350,
                                    fontFamily: "Poppins, Helvetica, sans-serif",

                                }}
                                defaultValue={video}
                                onChange={handleVideoChange}
                            />

                            <Typography>
                                <span
                                    style={{
                                        fontSize: 12,
                                        color: "#464E5F",
                                        marginLeft: 5,
                                        fontFamily: "Poppins, Helvetica, sans-serif",
                                    }}
                                >
                                    Please enter <span
                                        style={{
                                            color: "#464E5F",
                                            fontWeight: "600",
                                            fontFamily: "Poppins, Helvetica, sans-serif",
                                            fontSize: 12
                                        }}>
                                        resource URL
                                    </span>
                                </span>
                            </Typography>


                        </FormControl>
                    </div>
 */}






                    {/* 
                    <div
                        style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', width: '100%', marginTop: 0 }}
                    >
                        <Checkbox
                            checked={agree}
                            onChange={handleAgreeToggle}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />

                        <Link to="/publisher-ebook/settings/terms-conditions" target="_blank" rel="noopener noreferrer">
                            <Typography
                                variant="body2">
                                I agree to Terms &amp; Conditions
                            </Typography>
                        </Link>

                    </div> */}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: 'center',
                            marginTop: 0
                        }}>
                        {currBook ?
                            <Typography
                                variant="caption"
                                style={{ color: 'red' }}
                            >
                                Please note you are about to replace the current book associated with this record.
                                If you have added other pages, make sure the previous pages are correctly ordered to keep the mapped multimedia
                                still in place.
                            </Typography>
                            :
                            <></>
                        }
                    </div>



                    <div style={{ display: "flex", width: '95%', justifyContent: "space-between", margin: 10 }}>
                        <Button
                            variant="contained"
                            onClick={() => props.onCloseDrawer(props.anchorType, false)}
                        >Cancel</Button>

                        {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}

                        {

                            currBook ?
                                progress
                                    || progressBook
                                    || submitProg
                                    || (
                                        !InstValue || !subjectType || !standardType || !boardType
                                        // || 
                                        // !les 
                                        || !title
                                        // || !video 
                                        // || !author 
                                        || !selTerm
                                        ||
                                        !bookPrice
                                        ||
                                        (!selectedFile && !currBook)
                                        // ||
                                        // !agree
                                    )
                                    ?

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}

                                        disabled
                                    >
                                        Save
                                    </Button>
                                    :

                                    <Button

                                        variant="contained"
                                        color="primary"
                                        style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}
                                        onClick={() => {
                                            handleSingleEbookUpload()
                                        }}


                                    >
                                        Save
                                    </Button>

                                :


                                progress
                                    || progressBook
                                    || submitProg
                                    || (
                                        !InstValue || !subjectType || !standardType || !boardType
                                        // || 
                                        // !les 
                                        || !title
                                        // || !video 
                                        // || !author 
                                        || !selTerm
                                        ||
                                        !bookPrice
                                        ||
                                        // !selectedFile
                                        (!selectedFiles || !selectedFiles.length) // no files selected at all
                                        // ||
                                        // !agree
                                    )
                                    ?

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}

                                        disabled
                                    >
                                        Save
                                    </Button>
                                    :

                                    <Button

                                        variant="contained"
                                        color="primary"
                                        style={{ fontFamily: "Poppins, Helvetica, sans-serif", textTransform: "none" }}
                                        onClick={() => {
                                            handleSingleEbookUpload()
                                        }}


                                    >
                                        Save
                                    </Button>


                        }
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 20 }}>
                        {submitProg ?

                            <CircularProgress />
                            :
                            <></>
                        }


                        {/* </Link> */}

                        {/* snackbars */}
                        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                            <Alert2 onClose={handleSnackClose} severity="success">
                                E-Book saved successfully!
                            </Alert2>
                        </Snackbar>

                        {/* error snack */}
                        <Snackbar open={errSnackOpen} autoHideDuration={6000} onClose={handleErrSnackClose}>
                            <Alert2 onClose={handleErrSnackClose} severity="danger">
                                Error uploading E-Book!
                            </Alert2>
                        </Snackbar>
                    </div>

                </div>




                {/* <div style={{ justifyContent: "center", display: "flex", marginTop: 50 }}>
                    <span
                        style={{
                            textAlign: "center",
                            fontFamily: "Poppins, Helvetica, sans-serif",
                            fontSize: 14,
                            alignSelf: "center"
                        }}>
                        To Add more Fields Go to  <span
                            style={{ fontWeight: "500" }}
                        >
                            <Link to="/settings" style={{ textDecoration: "none", color: "#000" }}>
                                Settings
                                </Link>
                        </span> Page</span>
                </div> */}




            </TabPanel>






        </div >
    );
}
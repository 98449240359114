import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Route, Link} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LOGO from '../../assets/PustakLogo.png';
import AuthAPI from '../../http/auth';
import { useHistory } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import Copyright from './slots/Copyright'

function Copyright2() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://pustak.co/">
                Pustak.co
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const logo = LOGO

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();

    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [passw, setPassw] = useState('')
    const [agree, setAgree] = useState(false)
    const [value, setValue] = React.useState('BOTH');
    const [isLoading, setIsLoading] = React.useState(false)
    const history = useHistory();

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleAgree = (e) => {
        console.log(`handleagree e ${e.target}`)
        const { name, value, checked } = e.target
        console.log(`handleagree value ${checked}`)

        setAgree(checked)
    }

    const handleFName = (e) => {
        const { name, value } = e.target

        setFName(value)
    }

    const handleLName = (e) => {
        const { name, value } = e.target

        setLName(value)
    }

    const handleEmail = (e) => {
        const { name, value } = e.target

        setEmail(value)
    }

    const handlePassw = (e) => {
        const { name, value } = e.target

        setPassw(value)
    }

    const handleDoContProvRegister = async () => {

        const body =
        {
            user_id: 0,
            user_password: passw,
            prov_name_text: `${fName} ${lName}`,
            prov_email_text: email,
            company_id: 1,
            prov_status: 1,
            prov_cover_url: "",
            bank_account_name: "",
            bank_account_number: "",
            banck_account_IFSC: "",
            bank_branch: "",
            bank_name: "",
            phone_number: ""

        }

        const resp = await AuthAPI.doRegisterContProv(body)
        console.log(`response from contprov reg ${JSON.stringify(resp)}`)

        if (resp && resp.data) {
            // now navigate to thank you page
            history.push({ pathname: '/user/thank-you', state: { foo: 'baz' } });
        } else {
            console.log(`error saving content provider`)
        }

        setIsLoading(false)
    }

    const handleDoBookPubRegister = async () => {

        const body =
        {
            user_id: 0,
            user_password: passw,
            pub_name_text: `${fName} ${lName}`,
            pub_email_text: email,
            pub_desc: "BOOK",
            pub_logo: "",
            pub_status: 1,
            company_id: 1,
            bank_account_name: "",
            bank_account_number: "",
            banck_account_IFSC: "",
            bank_branch: "",
            bank_name: "",
            phone_number: ""

        }

        const resp = await AuthAPI.doRegisterBookPub(body)
        console.log(`response from Bookpub reg ${JSON.stringify(resp)}`)

        if (resp && resp.data) {
            // now navigate to thank you page
            history.push({ pathname: '/user/thank-you', state: { foo: 'baz' } });
        } else {
            console.log(`error saving book publisher`)
        }

        setIsLoading(false)

    }

    const handleDoBothRegister = async () => {

        const body =
        {
            user_id: 0,
            user_password: passw,
            pub_name_text: `${fName} ${lName}`,
            pub_email_text: email,
            pub_desc: "BOOK",
            pub_logo: "",
            pub_status: 1,
            company_id: 1,
            bank_account_name: "",
            bank_account_number: "",
            banck_account_IFSC: "",
            bank_branch: "",
            bank_name: "",
            phone_number: "",
        }

        const resp = await AuthAPI.doRegisterBoth(body)
        console.log(`response from Bookpub reg ${JSON.stringify(resp)}`)

        if (resp && resp.data) {
            // now navigate to thank you page
            history.push({ pathname: '/user/thank-you', state: { foo: 'baz' } });
        } else {
            console.log(`error saving content and publisher`)
        }

        setIsLoading(false)

    }
    
    const handleDoRegister = () => {
        setIsLoading(true)
        // decide which register to process

        if (value == 'CONTENTPROVIDER')
            handleDoContProvRegister()
        else if (value == 'EBOOKPUBLISHER')
            handleDoBookPubRegister()
        else if(value == 'BOTH')
            handleDoBothRegister()
    }

    let btnDisabled = false;

    if (!fName
        || !email
        || !passw
        || !agree) {
        btnDisabled = true;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <img src={logo} alt="Logo" height={64} />


                <form className={classes.form} action="#" onSubmit={() => { return false }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                onChange={handleFName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                onChange={handleLName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={handleEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handlePassw}
                            />
                        </Grid>


                        <Grid item xs={12}
                            style={{
                                marginTop: 20
                            }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">What would you like to publish?</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                    {/* <FormControlLabel value="EBOOKPUBLISHER" control={<Radio />} label="Book" /> */}
                                    <FormControlLabel value="CONTENTPROVIDER" control={<Radio />} label="Multimedia" />
                                    <FormControlLabel value="BOTH" control={<Radio />} label="Book" />
                                    {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" checked={agree}
                                    onChange={handleAgree} />}
                                label="I agree to terms and conditions."
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={btnDisabled}
                        onClick={handleDoRegister}
                    >
                        {(isLoading) ?
                            <CircularProgress />
                            :
                            <>Sign Up</>
                        }
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to="/" variant="body2" style={{textDecoration:"none",color:"blue"}}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}
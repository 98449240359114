import axios from "axios";
import Constants from "../../resource/Constants";
import { CHPDETAILS_LIST_FAIL, CHPDETAILS_LIST_REQUEST, CHPDETAILS_LIST_SUCCESS } from "../constants/chapterConstants";




const listChpGet = (subId, boardId) => async (dispatch) => {
    try {
        dispatch({ type: CHPDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/standard-chapter/?subid=${subId}&board_id=${boardId}`)
        console.log("data in list chapters  get", data)
        dispatch({ type: CHPDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: CHPDETAILS_LIST_FAIL, payload: error.message })

    }
}


const listChpGet2 = (subId, boardId, clsStd) => async (dispatch) => {
    try {
        dispatch({ type: CHPDETAILS_LIST_REQUEST })

        const { data } = await axios.get(Constants.Application.PLATFORM_END_URL + `/standard-chapter1/?subid=${subId}&board_id=${boardId}&classroom_std=${clsStd}`)
        console.log("data in list chapters  get", data)
        dispatch({ type: CHPDETAILS_LIST_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: CHPDETAILS_LIST_FAIL, payload: error.message })

    }
}

export { listChpGet, listChpGet2 }
/**
 * Book Reader screen
 *
 */

/**imports start here  */
import React, { useEffect, useState } from "react";
import { ReactReader } from "react-reader";
import book from "../../assets/famouspaintings.epub";
import Fab from "@material-ui/core/Fab";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import Draggable from "react-draggable";
import book1 from "../../assets/AlicesAdventures.epub";
import Button from "@material-ui/core/Button";
import { Route, Link, BrowserRouter, Switch } from "react-router-dom";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Drawer, IconButton, Paper } from "@material-ui/core";
import UploadEbookScreen from "./UploadEbookScreen";

import CircularProgress from '@material-ui/core/CircularProgress';
import API from "../../http/http";
import PARTNERAPI from "../../http/httppartner";
import PUBLISHERAPI from "../../http/publisher";
import axios from "axios";
import book2 from "../../assets/AroundtheWorld.epub";
import { ContactMailSharp, ContactSupportOutlined, TrainRounded, Update } from "@material-ui/icons";
import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LeftCard from "./slots/LeftCard";
import Leftbar from "../contentmaker/slots/Leftbar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TurnBook from "./slots/TurnBook";
import "../../css/styles.css";
import noVideo from "../../assets/screenshot.png";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
// import { DraggableContainer, DraggableChild } from 'react-drag-guideline'
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as RippleIcon } from '../../assets/Ripple38.svg'
import DocViewer from "react-doc-viewer";
// Core viewer
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import { useDispatch, useSelector } from "react-redux";
import { listChpGet, listChpGet2 } from "../../redux/actions/chapterAction";
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
/**imports end here */


function FileViewer(props) {
    const { url } = props
    const docs = [
        { uri: url },
    ];

    return <DocViewer documents={docs} />;
}



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**Dummy data for map-content */
const data = [
    {
        color: "red",
        value: "#f00",
        page: 2,
        title: "introduction2",
        x_axis: 300,
        y_axis: 1000,
    },
    {
        color: "green",
        value: "#0f0",
        page: 4,
        title: "introduction5",
        x_axis: 300,
        y_axis: 400,
    },
    {
        color: "blue",
        value: "#00f",
        page: 6,
        title: "introduction10",
        x_axis: 650,
        y_axis: 700,
    },
    {
        color: "cyan",
        value: "#0ff",
        page: 8,
        title: "introduction12",
        x_axis: 800,
        y_axis: 710,
    },
    {
        color: "magenta",
        value: "#f0f",
        page: 10,
        title: "introduction14",
        x_axis: 700,
        y_axis: 900,
    },
    {
        color: "yellow",
        value: "#ff0",
        page: 12,
        title: "introduction16",
        x_axis: 900,
        y_axis: 500,
    },
    {
        color: "black",
        value: "#000",
        page: 14,
        title: "introduction18",
        x_axis: 600,
        y_axis: 800,
    },
];

/**drawer width */
const drawerWidth = 250;

/**styling for book reader */
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbar1: {
        paddingRight: 24,
        height: 40,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    toolbarIcon1: {
        top: "45vh",
        bottom: 0,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // marginLeft:70,
        backgroundColor: "#1976d2",
        color: "#ffffff",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: "blue",
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        backgroundColor: "#ffffff",
        scrollbarColor: "green",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    large: {
        width: "80%",
        height: 50,
        borderRadius: 0,
    },
}));

/**video dialog box draggable function */
function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles3 = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    customWidth: {
        maxWidth: 500,
    },
    noMaxWidth: {
        maxWidth: "none",
    },
}));

const longText = `
  click and hold the Play button to drag the play button on book to map the content,
  After click release A map content button will appear to map the content on book
  `;
const pointerText = `
  drag the arrow to align the mapped icons
`
function BookReaderScreen(props) {
    const classes = useStyles();
    const classes1 = useStyles3();
    const hasWindow = typeof window !== 'undefined';
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;

    const [location, setLocation] = useState(null);
    const [data, setData] = useState(null);
    const [showButton, setShowButton] = useState(false);
    const [right, setRight] = useState(false);
    const [bookId, setBookId] = useState(null);
    const [ebookContentUrl, setEbookContentUrl] = useState(null);
    const [ebookContent, setEbookContent] = useState(null);
    const [xPos, setXPos] = useState(null);
    const [yPos, setYPos] = useState(null);
    const [xPos1, setXPos1] = useState(null)
    const [yPos1, setYPos1] = useState(null)
    const [pageNum, setPageNum] = useState(null);
    // const [subId, setSubId] = useState(null);
    const [contId, setContId] = useState(null);
    const [showMapButton, setShowMapButton] = useState(false);
    const [showMapButton1, setShowMapButton1] = useState(false);
    const [providers, setProviders] = useState(null);
    const [mappedData, setMappedData] = useState("");
    const [open, setOpen] = useState(false);
    const [fabData, setFabData] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [bookPages, setBookPages] = useState(null);
    const [leftPage, setLeftPage] = useState(null);
    const [rightPage, setRightPage] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [FabDataSingle, setFabDataSingle] = useState(null);
    const [showTip, setShowTip] = useState(true)
    const [teachPrev, setTeachPrev] = useState(false)
    const [xPointerPos, setXPointerPos] = useState(20)
    const [yPointerPos, setYPointerPos] = useState(0)
    const [xPointerPos1, setXPointerPos1] = useState((width - 200))
    const [yPointerPos1, setYPointerPos1] = useState(0)
    const [hideIcons, setHideIcons] = useState(false)
    const [hideIcons1, setHideIcons1] = useState(false)
    const [showTipPoint, setShowTipPoint] = useState(true)
    const [showTipPoint1, setShowTipPoint1] = useState(true)
    const [openDelDialog, setOpenDelDialog] = useState(false)
    const [contentId, setContentId] = useState("")
    const [delSnackOpen, setDelSnackOpen] = useState(false)
    const [inHover, setInHover] = useState(false)
    const [inHover1, setInHover1] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [actvRow, setActvRow] = React.useState(null)
    const [actvRow2, setActvRow2] = React.useState(null)
    const [actvRow3, setActvRow3] = React.useState(false)
    const [contentTypeName, setContentTypeName] = React.useState("")
    const [videoPlaying, setVideoPlaying] = useState(false)
    const [bookPdfPath, setBookPdfPath] = useState(null)
    const [bookDetails, setBookDetails] = useState(null)
    const [topicId, setTopicId] = useState(0);
    const [subTopicId, setSubTopicId] = useState(0);
    const [subId, setSubId] = useState(0);
    const [topicName, setTopicName] = useState('')
    const [subTopicName, setSubTopicName] = useState('')
    const [chapters, setChapters] = useState(null);
    
    const dispatch = useDispatch()

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(slots: ToolbarSlot) => {
                const {
                    CurrentPageInput,
                    CurrentScale,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    // ZoomIn,
                    // ZoomOut,
                } = slots;
                return (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        {/* <div style={{ padding: '0px 2px' }}>
                            <ZoomOut>
                                {(props) => (
                                    <button
                                        style={{
                                            backgroundColor: '#357edd',
                                            border: 'none',
                                            borderRadius: '4px',
                                            color: '#ffffff',
                                            cursor: 'pointer',
                                            padding: '8px',
                                        }}
                                        onClick={props.onClick}
                                    >
                                        Zoom out
                                    </button>
                                )}
                            </ZoomOut>
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <CurrentScale>{(props) => <span>{`${Math.round(props.scale * 100)}%`}</span>}</CurrentScale>
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomIn>
                                {(props) => (
                                    <button
                                        style={{
                                            backgroundColor: '#357edd',
                                            border: 'none',
                                            borderRadius: '4px',
                                            color: '#ffffff',
                                            cursor: 'pointer',
                                            padding: '8px',
                                        }}
                                        onClick={props.onClick}
                                    >
                                        Zoom in
                                    </button>
                                )}
                            </ZoomIn>
                        </div> */}
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <GoToPreviousPage>
                                {(props) => (
                                    <button
                                        style={{
                                            backgroundColor: props.isDisabled ? '#96ccff' : '#357edd',
                                            border: 'none',
                                            borderRadius: '4px',
                                            color: '#ffffff',
                                            cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                                            padding: '8px',
                                        }}
                                        disabled={props.isDisabled}
                                        onClick={props.onClick}
                                    >
                                        Previous page
                                    </button>
                                )}
                            </GoToPreviousPage>
                        </div>
                        <div style={{ padding: '0px 2px', width: '4rem' }}>
                            <CurrentPageInput />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            / <NumberOfPages />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <GoToNextPage>
                                {(props) => (
                                    <button
                                        style={{
                                            backgroundColor: props.isDisabled ? '#96ccff' : '#357edd',
                                            border: 'none',
                                            borderRadius: '4px',
                                            color: '#ffffff',
                                            cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                                            padding: '8px',
                                        }}
                                        disabled={props.isDisabled}
                                        onClick={props.onClick}
                                    >
                                        Next page
                                    </button>
                                )}
                            </GoToNextPage>
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const renderPage: RenderPage = (props: RenderPageProps) => (
        <div
            style={{
                width: '50%',
                height: '50%'
            }}>
            {props.canvasLayer.children}
            {props.width}
            {/* <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '50%',
                }}
            >
                <div
                    style={{
                        color: 'rgba(0, 0, 0, 0.2)',
                        fontSize: `${8 * props.scale}rem`,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        transform: 'rotate(-45deg)',
                        userSelect: 'none',
                    }}
                >
                    Draft
                </div>
            </div> */}
            {props.annotationLayer.children}
            {props.textLayer.children}
        </div>
    );

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: defaultTabs => [
            // Remove the attachments tab (\`defaultTabs[2]\`)
            // defaultTabs[0], // Bookmarks tab
            defaultTabs[0], // Thumbnails tab
        ],
    });


    const handleMenuClick = (event, row) => {
        handleSnackClose()
        console.log(`handlemenuclick() called..`)
        // open dialog
        setOpenDelDialog(true, () => {
            handleSnackClose()
            setActvRow3(true, () => {
                handleSnackClose()

                // console.log(`event current target ${event.currentTarget}`)
                // setAnchorEl(event.currentTarget);
                console.log(`actvRow ${JSON.stringify(row)}`)
                setActvRow(row)
                console.log(`actvRow is now set..`)

            })
        })

    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    console.log(" windows width at turn js", (width - 400) / 2, height - 20)


    const Positiondata = [
        { id: 1, position: { x: 100, y: 10 } },
        { id: 2, position: { x: 400, y: 200 } },
    ];

    const containerStyle = {
        height: 600,
        position: "relative",
    };

    // const book1 = "http://www.africau.edu/images/default/sample.pdf"

    const updatePages = (leftPage, rightPage) => {
        setLeftPage(leftPage);
        setRightPage(rightPage);
    };

    const handleSnackOpen = () => {
        setSnackOpen(true);
    };

    const handleDelSnackOpen = () => {
        setDelSnackOpen(true)
    }

    const handleSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen(false);
    };

    const handleDelSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setDelSnackOpen(false);
    };


    const epubLocation = (epubcifi) => {
        setLocation(epubcifi);
        setShowMapButton(false);
    };

    /** dialog function starts here*/
    const handleClickOpen = (VideoUrl, contentType) => {
        console.log("video Url at handle click open", VideoUrl);
        console.log('video type at handle click open', contentType);

        if (!contentType) {
            contentType = 'video/mp4'
        }
        setVideoUrl(VideoUrl);
        setContentTypeName(contentType)
        setOpen(true);
    };

    const CloseHandleClick = () => {
        setOpen(false);
        setVideoPlaying(false)
    };
    /**dialog function ends here  */


    /**draggable icon functions starts here  */
    const handleStart = (e, dt, row) => {
        setShowMapButton(false);
        setShowTip(false)

        if (row) {
            setActvRow(row)

            // update coordinates if not null
            setXPos(row.x_pos)
            setXPos1(row.x_pos)
            setYPos(row.y_pos)
            setYPos1(row.y_pos)
        }
    };

    const handleStart1 = (e, dt, row) => {
        console.log(`handlestart e is ${e})}`)
        console.log(`handlestart dt is ${dt}`)
        console.log(`handlestart row is ${JSON.stringify(row)}`)
        setShowMapButton(false);
        setShowTip(false)

        if (row) {
            setActvRow(row)

            // update coordinates
            setXPos1(row.x_pos)
            setYPos1(row.y_pos)
        }
    };

    const handleStartPointer = () => {
        // setShowMapButton(false);
        // setShowTip(false)
        setShowTipPoint(false)
    };

    const handleStartPointer1 = () => {
        // setShowMapButton(false);
        // setShowTip(false)
        setShowTipPoint1(false)
    };

    const handleStop = (e, dt, row) => {
        console.log('handleStop called..')
        setShowMapButton(true);
        setShowTip(true)

        console.log('fabData ', JSON.stringify(fabData));

        // first time mapping..
        if (fabData != null) {

            /*
            postEbookMapContent(
                bookId,
                fabData.id,
                fabData.topic_sub_id,
                xPos,
                yPos,
                location,
                location,
//                fabData.content_location
                ''
            );
            */

            console.log(`first time mapping`)
            UpdateContent(fabData, xPos, yPos)
            setShowButton(false)

        } else {

            if (!actvRow3) { // not delete either..

                UpdateContent() // current one
            } else {
                console.log(`error updating first or right side content map`)
            }

            console.log(" working only once ")
            setShowButton(false)

        }
    };

    const handleStop1 = (e, dt, row) => {
        setShowMapButton(true);
        setShowTip(true)
        console.log(`handlestop1 called..`)
        console.log(`actvrow3 ${actvRow3}`)
        console.log(`row passed is ${JSON.stringify(row)}`)

        console.log(`row set, now update content..`)
        // verify if it's delete button clicked
        if (!row) {
            UpdateContent()
        } else {
            console.log('actvRow3 is ', row);
            UpdateContent(row)
        }
        console.log(" working only once ")
        setShowButton(false)


    };

    const handleStopPointer = () => {
        // setShowMapButton(true);
        // setShowTip(true)
        setHideIcons(false)
        setShowTipPoint(true)

    };

    const handleStopPointer1 = () => {
        // setShowMapButton(true);
        // setShowTip(true)
        setHideIcons1(false)
        setShowTipPoint1(true)

    };

    const eventLogger = (e: MouseEvent, data: Object, row) => {
        console.log(`event logger only`)
        console.log("Event: ", e);
        console.log("Data: ", data);
        console.log("Data x: ", data.x);
        console.log("Data y: ", data.y);

        setXPos(data.x);
        setXPos1(data.x);
        setYPos(data.y);
        setYPos1(data.y);

        setShowMapButton(false);

        console.log("x y position", xPos, yPos);
        // if(row)
        // setActvRow(row)
    };


    const eventLogger1 = (e: MouseEvent, data: Object, row) => {
        console.log(`event logger 1`)
        console.log("Event: ", e);
        console.log("Data: ", data);
        console.log("Data x: ", data.x);
        console.log("Data y: ", data.y);

        setXPos1(data.x);
        setYPos1(data.y);
        setShowMapButton1(false);

        console.log("x y position", xPos1, yPos1);

        // if(row)
        // setActvRow(row)
    };

    // UNUSED
    const eventLoggerPointer = (e: MouseEvent, data: Object) => {
        console.log("Event at pointer: ", e);
        console.log("Data at pointer: ", data);
        console.log("Data x at pointer: ", data.x);
        console.log("Data y at pointer: ", data.y);

        setXPointerPos(data.x);
        setYPointerPos(data.y);

        console.log(`all mapped content ${JSON.stringify(mappedData)}`)

        if (mappedData && mappedData.data && mappedData.data.length) {
            mappedData.data.map((cont, index) => {
                if (leftPage == cont.page_num1 && rightPage == cont.page_num2 && cont.x_pos > ((width - 400) / 2)) {
                    // right content
                    // console.log(`all mapped content - right content ${cont.id}`)
                } else {
                    console.log(`all mapped content - left content ${cont.id}`)

                    // only update left content
                    // update state and run this

                    console.log(`all mapped content - left content map setActvRow() complete`)
                    UpdateContent(cont, data.x, cont.y_pos)

                }

            })
        }

        setHideIcons(true)
        setShowTipPoint(false)

        // setShowMapButton(false);

        console.log("x y position at pointer", xPointerPos, yPointerPos);
    };

    const eventLoggerPointer1 = (e: MouseEvent, data: Object) => {
        console.log("Event at pointer: ", e);
        console.log("Data at pointer: ", data);
        console.log("Data x at pointer: ", data.x);
        console.log("Data y at pointer: ", data.y);

        setXPointerPos1(data.x);
        setYPointerPos1(data.y);


        console.log(`all mapped content ${JSON.stringify(mappedData)}`)

        if (mappedData && mappedData.data && mappedData.data.length) {
            mappedData.data.map((cont, index) => {
                if (leftPage == cont.page_num1 && rightPage == cont.page_num2 && cont.x_pos > ((width - 400) / 2)) {
                    // right content
                    console.log(`all mapped content - right content ${JSON.stringify(cont)}`)

                    // only update left content
                    // update state and run this

                    console.log(`all mapped content - right content map setActvRow() complete`)
                    UpdateContent(cont, data.x, cont.y_pos)

                } else {
                    // console.log(`all mapped content - left content ${cont.id}`)

                }

            })
        }

        setHideIcons1(true)
        setShowTipPoint1(false)


        // setShowMapButton(false);

        console.log("x y position at pointer", xPointerPos1, yPointerPos1);
    };


    /** draggable icon functions ends here*/

    let bookPage = [];
    /**get Ebook Content starts here */
    const getEbookContent = async (book_id) => {
        console.log("ebook content function is calling");
        // const resp = await PUBLISHERAPI.GetEbookContentOld(book_id);
        const resp = await PUBLISHERAPI.GetEbookContent(book_id);
        console.log("response at eBook Content", resp.data.data);
        
        const data = resp.data.data
        setEbookContentUrl(data.ebook_location_url);
        setEbookContent(data);
        setBookPages(data.ebook_details);
        setSubId(data.subject_id);

        setBookPdfPath(data.ebook_location_url)

        console.log(`book pdf path ${data.book_pdf_path}`)
        let temp = data.ebook_details;
        setBookDetails(data)
        
        const boardId = data.institute_board_id;
        const subjectId = data.subject_id;
        const clsStd = data.classroom_std;

        getChapters(subjectId, boardId, clsStd);

        temp.map((detail, index) => {
            console.log("book page details at book reader screen", detail, index);
            if (index == detail.page_num) {
                bookPage.push(detail.page_url);
            }
        });

        console.log("bookPageUrls Array", bookPage);
    };

    const getChapters = (subjectId, boardId, clsStd) => {
        console.log('get chapters called...bookDetails ', bookDetails);
        
        // if (bookDetails) {
            // console.log('get chapters called bookDetails 2 ', bookDetails)
            // const instId = bookDetails.institute_type_id;
            // const boardId = bookDetails.institute_board_id;
            // const subjectId = bookDetails.subject_id;

            // dispatch(listChpGet2(subjectId, boardId, clsStd));

            // fetch global chapters
            dispatch(listChpGet2(subjectId, 0, clsStd));
        // }
    }

    /** get Ebook content ends here */

    /**getEbook Mapped Content starts here  */
    const getEbookMappedContentDataOld = async (book_id) => {
        console.log("ebook mapped content function is calling");
        const resp = await PUBLISHERAPI.getEbookMappedContentOld(book_id);
        console.log("resposne at get Ebook Mapped content", resp);
        if (resp && resp.data.data) {
            setMappedData(resp.data);
        }
    };

    const getEbookMappedContentData = async (book_id) => {
        console.log("ebook mapped content function is calling");
        const resp = await PUBLISHERAPI.getEbookMappedContent(book_id);
        console.log("resposne at get Ebook Mapped content", resp);
        if (resp && resp.data) {
            setMappedData(resp.data);
        }
    };

    /**getEbook Mapped Content ends here  */

    /**Post content Data api starts here*/

    const postEbookMapContent = async (
        bookid,
        contid,
        topicsubid,
        xpos,
        ypos,
        pagenum,
        chaptername,
        contenturl
    ) => {
        console.log(
            "data while posting",
            bookid,
            contid,
            topicsubid,
            xpos,
            ypos,
            pagenum,
            chaptername,
            contenturl
        );

        let DataObjOld = {
            content_id: contid,
            subject_id: topicsubid,
            book_id: bookid,
            x_pos: (xpos * 2),
            y_pos: (ypos * 2),
            page_num1: leftPage,
            page_num2: rightPage,
            page_num: 0,
            chapter_name: "0",
            content_location: '0',
        };

        let DataObj = {
            chapter_id: 0,
            subject_id: subId,
            topic_id: topicId,
            sub_topic_id: subTopicId,
            book_id: bookid,
            x_pos: (xpos * 2),
            y_pos: (ypos * 2),
            page_num1: leftPage,
            page_num2: rightPage,
            page_num: 0,
            chapter_name: "0",
            content_location: '0',
        };

        console.log("data object at ap content ", JSON.stringify(DataObj))
        // const resp = await PUBLISHERAPI.postEbookMappedContentOld(DataObj);
        const resp = await PUBLISHERAPI.postEbookMappedContent(DataObj);
        console.log(`response at epub book map cotent ${(resp)}`);

        if (resp) {
            if (bookId) {
                console.log(" gettig content data ====")
                getEbookMappedContentData(bookId);
                setTeachPrev(true)
            }

            handleSnackOpen();
        }
    };

    /**post content Data api ends here */



    const UpdateContent = async (cont, xPos, yPos) => {

        if ((actvRow && actvRow.subject_id) || cont) {
            console.log(`actvRow in update content ${JSON.stringify(actvRow)}`)
            console.log(`cont in update content ${JSON.stringify(cont)}`)
            console.log(`xpos1 ${xPos1} ypos1 ${yPos1}`)
            const body1 = {
                book_id: bookId,
                content_id: (cont) ? cont.id : actvRow.content_id,//contentId,
                subject_id: (cont) ? cont.subject_id : actvRow.subject_id,//fabData.subject_id,
                chapter_name: "0",
                x_pos: (xPos) ? xPos : xPos1,
                y_pos: (yPos) ? yPos : yPos1,
                page_num1: leftPage,
                page_num2: rightPage,
                page_num: 0,
                //content_location: (cont) ? cont.content_location : actvRow.content_location, //fabData.content_location
                content_location: '0'

            }

            let body = null
            let body3 = null;
            let contId = 0;

            // new one
            if (cont) {
                contId = cont.id;

                // DON'T USE
                body = {
                    content_id: cont.topic_sub_id,
                    subject_id: subId,
                    book_id: bookId,
                    x_pos: (xPos) ? (xPos * 2) : (xPos1 * 2),
                    y_pos: (yPos) ? (yPos * 2) : (yPos1 * 2),
                    page_num1: leftPage,
                    page_num2: rightPage,
                    page_num: 0,
                    chapter_name: "0",
                    content_location: '0',
                }

                // USE THIS
                body3 = {
                    chapter_id: 0,
                    topic_id: cont.topic_id,
                    sub_topic_id: (cont.sub_topic_id)?cont.sub_topic_id:cont.topic_sub_id,
                    topic_name: cont.topic_name,
                    sub_topic_name: (cont.sub_topic_name)?cont.sub_topic_name:cont.topic_sub_name,
                    chapter_name: '0',
                    subject_id: subId,
                    book_id: bookId,
                    content_location: '0',
                    x_pos: (xPos) ? (xPos * 2) : (xPos1 * 2),
                    y_pos: (yPos) ? (yPos * 2) : (yPos1 * 2),
                    page_num1: leftPage,
                    page_num2: rightPage,
                    page_num: 0,
                }
    
            } else if (actvRow) {
                contId = actvRow.content_id;

                // DON'T USE
                body = {
                    id: actvRow.id,
                    content_id: actvRow.content_id,
                    subject_id: subId,
                    book_id: bookId,
                    x_pos: (xPos) ? (xPos * 2) : (xPos1 * 2),
                    y_pos: (yPos) ? (yPos * 2) : (yPos1 * 2),
                    page_num1: leftPage,
                    page_num2: rightPage,
                    page_num: 0,
                    chapter_name: "0",
                    content_location: '0',
                }

                // USE THIS
                body3 = {
                    chapter_id: 0,
                    topic_id: topicId,
                    sub_topic_id: subTopicId,
                    topic_name: topicName,
                    sub_topic_name: subTopicName,
                    chapter_name: '0',
                    subject_id: subId,
                    book_id: bookId,
                    content_location: '0',
                    x_pos: (xPos) ? (xPos * 2) : (xPos1 * 2),
                    y_pos: (yPos) ? (yPos * 2) : (yPos1 * 2),
                    page_num1: leftPage,
                    page_num2: rightPage,
                    page_num: 0,
                }

            }

            const body2 = {
                content_id: (cont) ? cont.id : actvRow.content_id,
                subject_id: (cont) ? cont.subject_id : actvRow.subject_id,
                book_id: bookId,
                x_pos: (xPos) ? xPos : xPos1,
                y_pos: (yPos) ? yPos : yPos1,
                page_num1: leftPage,
                page_num2: rightPage,
                page_num: 0,
                chapter_name: "0",
                // content_location: (cont) ? cont.content_location : actvRow.content_location,
                content_location: '0'
            }

            // fresh entry...
            if (!actvRow && cont) {
                console.log("AddContent body at content map location", JSON.stringify(body3))
                // const resp = await PUBLISHERAPI.updateBoookMappedContentOld(body, (cont) ? cont.id : actvRow.content_id)
                const resp = await PUBLISHERAPI.addBoookMappedContent(body3)
                console.log("AddContent updated data response is", resp)

                handleSnackOpen(true)
                // setActvRow(null) // so that we get no conflict
                // clear first time data
                setFabData(null)

                if (resp) {
                    getEbookMappedContentData(bookId)

                }
            } else if(actvRow) {
                // update
                console.log("UpdateContent body at content map location", JSON.stringify(body3))
                // const resp = await PUBLISHERAPI.updateBoookMappedContentOld(body, (cont) ? cont.id : actvRow.content_id)
                const resp = await PUBLISHERAPI.updateBoookMappedContent(body3, actvRow.id)
                console.log("UpdateContent updated data response is", resp)

                handleSnackOpen(true)
                // setActvRow(null) // so that we get no conflict
                // clear first time data
                // setFabData(null)

                if (resp) {
                    getEbookMappedContentData(bookId)

                }
            }
        }

    }

    const DeleteContent = async () => {


        console.log(" mapcontent id at delet function", actvRow.id)//contentId)
        const resp = await PUBLISHERAPI.deleteBoookMappedContent(actvRow.id)//contentId)
        console.log("response at delete content", resp)
        getEbookMappedContentData(bookId);

        handleDelSnackOpen()
        // close other dialog
        handleCloseDel()
        // clear row

    }

    const handleClickOpenDel = (id) => {
        console.log("content id at handle click open", id)
        setOpenDelDialog(true);
        setContentId(id)
    };

    const handleCloseDel = () => {
        setOpenDelDialog(false);
        // nullify delete flag
        // setActvRow3(false)
        handleMenuClose()
    };


    const onPageChange = (e: PageChangeEvent) => {
        console.log("page change event at book reader", e)
        // let currPage = e.current_page
        setLeftPage(e.currentPage);
        setRightPage(e.currentPage);
    };

    useEffect(() => {
        console.log("useEffect is calling ...................");

        const query = new URLSearchParams(window.location.search);
        const book_id = query.get("book_id");
        setBookId(query.get("book_id"));

        if (book_id) {
            getEbookMappedContentData(book_id);
            getEbookContent(book_id);
        }
        console.log("query at useEffect", query.get("book_id"));

        return () => {
            //
        };
    }, []);

    console.log("props at ebook reader", props);

    console.log("left page and right page", leftPage, rightPage);

    const FabDataUpdate = (mapData) => {
        setFabDataSingle(mapData);
        let temp = [];
        // temp.push({...FabDataSingle, mapData})
        console.log("temperary data at book  reader", mapData);
        setFabData(mapData);

        setTopicId(mapData.topic_id)
        setSubTopicId(mapData.topic_sub_id)
        setTopicName(mapData.topic_sub_name)
        setSubTopicName(mapData.topic_sub_name);
    };

    const onDocumentLoad = (e: DocumentLoadEvent) => {
        console.log(`full document ${(e.doc).toString()}`)
        console.log(`all keys of document ${Object.keys(e.doc)}`)
        // console.log(`styles of document ${e.doc.width} margin ${e.doc.margin}`)
        console.log(`Number of pages: ${e.doc.numPages}`);
    };

    console.log(`contentypename ${contentTypeName}`)
    let isVideo = false;
    let isImage = false;
    let isAudio = false;

    if (videoUrl) {
        if (contentTypeName == 'video/mp4' || videoUrl.includes('.mp4')) {
            isVideo = true;
            isImage = false;
            isAudio = false;
        }

        if (contentTypeName == 'audio/mp3' || videoUrl.includes('.mp3')) {
            isAudio = true;
            isVideo = false;
            isImage = false;
        }

        if (contentTypeName == 'image/jpg'
            || (videoUrl.includes('.jpg')
                || videoUrl.includes('.jpeg')
                || videoUrl.includes('.png')
                || videoUrl.includes('.gif'))
        ) {
            isImage = true;
            isVideo = false;
            isAudio = false;
        }
    }

    console.log("map data at reader", fabData)
    console.log('mapped data ', mappedData)
    
    return (
        <div className={classes.root}>
            <LeftCard
                status={props.hideSide}
                contData={props.sideProvList}
                FabStatus={(status) => {
                    setShowButton(status);
                }}
                FabMapData={(mapData) => {
                    FabDataUpdate(mapData);
                }}

                teachPrev={teachPrev}
                bookId={bookId}
                bookDetails={bookDetails}
            />

            <main className={classes.content}
                style={{
                    width: '65%',
                    margin: '0 auto'
                }}>
                {/* <DraggableContainer
                    style={containerStyle}
                >
                    {
                        Positiondata.map(({ id, position }, index) => {
                            const style = {
                                width: 100,
                                height: 100,
                                cursor: 'move',
                                background: '#8ce8df',
                            }


                            return (
                                <DraggableChild key={id} defaultPosition={position}>
                                    <div style={style} />
                                </DraggableChild>
                            )
                        })
                    }

                </DraggableContainer> */}


                <div style={{ height: "100vh", position: 'relative', width: '100%', margin: '0 auto' }}>

                    {(bookPdfPath) ?
                        <>
                            <Viewer
                                // fileUrl='/assets/pdf-open-parameters.pdf'
                                // fileUrl='https://arxiv.org/pdf/quant-ph/0410100.pdf'
                                fileUrl={bookPdfPath}
                                plugins={[
                                    // Register plugins
                                    defaultLayoutPluginInstance,

                                ]}
                                onDocumentLoad={onDocumentLoad}
                                onPageChange={onPageChange}
                                width={100}

                                defaultScale={SpecialZoomLevel.PageFit}
                            // renderPage={renderPage}
                            />







                            {/* BUTTON INITIAL DRAG */}
                            {(showButton) ? (
                                <div style={{ position: "absolute", zIndex: 2, left: 0, top: 0 }}>

                                    <Draggable
                                        axis="both"
                                        handle=".handle"
                                        defaultPosition={{ x: 50, y: 100 }}
                                        position={null}
                                        grid={[25, 25]}
                                        scale={1}
                                        onStart={handleStart}
                                        onDrag={eventLogger}
                                        onStop={handleStop}
                                        style={{ zIndex: 2, position: "absolute" }}
                                    >
                                        <div>
                                            <div className="handle">
                                                {showTip ?
                                                    <Tooltip title={longText} placement="right">
                                                        <Fab
                                                            aria-label="add"
                                                            size="medium"
                                                            style={{ backgroundColor: "#ffffff" }}
                                                        >
                                                            <PlayArrowOutlinedIcon
                                                                style={{ fontSize: 30, color: "grey" }}
                                                            />
                                                        </Fab>
                                                    </Tooltip>
                                                    :
                                                    <Fab
                                                        aria-label="add"
                                                        size="medium"
                                                        style={{ backgroundColor: "#ffffff" }}
                                                    >
                                                        {/* <PlayArrowOutlinedIcon
                                                style={{ fontSize: 30, color: "#3f51b5" }}
                                            /> */}

                                                        <RippleIcon />
                                                    </Fab>
                                                }
                                            </div>
                                        </div>
                                    </Draggable>

                                </div>
                            ) : (
                                <></>
                            )}

                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0
                                }}>
                                <div
                                    style={{
                                        height: 650,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "relative",
                                    }}
                                    className="scrollbar-hidden"
                                >
                                    {!hideIcons ?
                                        mappedData && mappedData.data && mappedData.data.length != 0 ? (
                                            mappedData.data.map((list, index) => {
                                                console.log(
                                                    "left and right ,page1,page2",
                                                    list.page_num1,
                                                    list.page_num2
                                                );

                                                console.log(`printing list id ${JSON.stringify(list)}`)
                                                console.log(`printing actvrow id ${JSON.stringify(actvRow)}`)

                                                console.log(`printing are they equal ${actvRow != null && actvRow.id == list.id}`)
                                                const opened = (actvRow2 != null && actvRow2.id == list.id)
                                                return (leftPage == list.page_num1) ? (
                                                    <div style={{ position: "absolute", zIndex: 3 }}>
                                                        <Draggable
                                                            axis="both"
                                                            handle=".handle"
                                                            defaultPosition={{ x: xPointerPos > 20 ? xPointerPos : (list.x_pos / 2), y: (list.y_pos / 2) }}
                                                            // defaultPosition={{ x: (xPointerPos > 20 && xPointerPos == list.x_pos) ? xPointerPos : list.x_pos, y: list.y_pos }}
                                                            position={null}
                                                            grid={[50, 50]}
                                                            scale={1}
                                                            onStart={(e, dt) => handleStart1(e, dt, list)}
                                                            onDrag={(e, dat) => eventLogger1(e, dat, list)}
                                                            onStop={(e, dat) => handleStop1(e, dat, list)}
                                                            style={{ zIndex: 2, position: "absolute" }}
                                                        >
                                                            <div>

                                                                <div className="handle">
                                                                    <Button
                                                                        aria-label="add"
                                                                        size="small"
                                                                        style={{
                                                                            backgroundColor: "Transparent",
                                                                            border: 0,
                                                                            /* borderBottomLeftRadius: 0,
                                                                            fontSize: 25, */
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                        }}
                                                                        onDoubleClick={() => {
                                                                            handleClickOpen(list.content_location, list.content_type_name);
                                                                        }}

                                                                        onMouseEnter={() => setActvRow2(list)}
                                                                        onMouseLeave={() => setActvRow2(null)}
                                                                    >
                                                                        {/* <div style={{ marginTop: 15, marginLeft: 3 }}>
                                                            <span
                                                                style={{
                                                                    textDecoration: "none",
                                                                    fontSize: 10,
                                                                    marginRight: -5,
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                {list.id}
                                                            </span>
                                                        </div> */}

                                                                        <div
                                                                            style={{
                                                                                position: 'relative',
                                                                                width: 38,
                                                                                height: 38
                                                                            }}>

                                                                            <div style={{ position: "absolute" }}>
                                                                                {opened &&
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            backgroundColor: "#fff",
                                                                                            width: 24,
                                                                                            height: 24,
                                                                                            borderRadius: 50,
                                                                                            // borderWidth: 0.5,
                                                                                            borderColor: "gray"

                                                                                        }}
                                                                                        onMouseLeave={() => setInHover(false)}
                                                                                    >
                                                                                        {/* <IconButton aria-label="delete" color="secondary" onClick={() => { handleClickOpenDel(list.id) }} > */}
                                                                                        <IconButton
                                                                                            style={{
                                                                                                width: 16,
                                                                                                height: 16
                                                                                            }}
                                                                                            aria-label="delete" color="secondary" onClick={(e) => handleMenuClick(e, list)} >
                                                                                            <CloseIcon style={{ width: 16, height: 16 }} />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            <PlayArrowOutlinedIcon
                                                                                // size={16}
                                                                                // height={16}
                                                                                // width={16}
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    alignSelf: 'center',
                                                                                    fontSize: 30,
                                                                                    color: "grey",
                                                                                    marginLeft: 7,
                                                                                    marginTop: 7,
                                                                                    zIndex: 3,
                                                                                    width: 24,
                                                                                    height: 24
                                                                                }}
                                                                            />


                                                                            <RippleIcon
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    alignSelf: 'center'
                                                                                }} />
                                                                        </div>
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        </Draggable>
                                                    </div>
                                                ) : (
                                                    <></>
                                                );
                                            })
                                        ) : (
                                            <></>
                                        )
                                        :
                                        <></>
                                    }



                                    {/* DELETE DIALOG */}

                                    <Dialog
                                        anchorEl={anchorEl}
                                        // open={openDelDialog} 
                                        // open={(anchorEl != null) ? Boolean(anchorEl) : false}
                                        // open={(actvRow != null && list.id == actvRow.id)}
                                        open={openDelDialog}
                                        onClose={() => { handleCloseDel(); }} aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {actvRow != null ?
                                                    <>
                                                        {/* Are you sure you want to delete? */}
                                                        Do you want to delete this content of id {actvRow.id} ?
                                                    </>
                                                    :
                                                    <>
                                                        Something went wrong..
                                                    </>
                                                }

                                            </DialogContentText>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => { handleCloseDel(); }} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={() => { DeleteContent(); }} color="primary">
                                                yes
                                            </Button>
                                        </DialogActions>
                                    </Dialog>


                                    {/* PLAYER DIALOG */}
                                    <Dialog
                                        open={open}
                                        onClose={() => {
                                            CloseHandleClick();
                                        }}
                                        PaperComponent={PaperComponent}
                                        aria-labelledby="draggable-dialog-title"
                                    >
                                        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">

                                        </DialogTitle>
                                        <DialogContent style={{ width: "100%" }}>

                                            {/* {contentTypeName == "video/mp4" ? */}
                                            {isVideo &&
                                                // videoUrl ? (
                                                <ReactPlayer url={videoUrl} width={"550px"} playing={videoPlaying} controls={true} />
                                                // ) : (
                                            }


                                            {/* ) */}
                                            {/* : */}
                                            {/* contentTypeName == "application/pdf" */}
                                            {/* ? */}
                                            {/* <FileViewer url={videoUrl} /> */}
                                            {/* : */}
                                            {/* contentTypeName == "image/jpg" */}
                                            {/* ? */}
                                            {/* // <FileViewer url={videoUrl} /> */}
                                            {isImage &&
                                                <img src={videoUrl} width="100%" />
                                            }
                                            {/* : */}
                                            {/* contentTypeName == "audio/mp3" */}
                                            {/* ? */}
                                            {isAudio &&
                                                <ReactPlayer url={videoUrl} width={"550px"} playing={videoPlaying} controls={true} />
                                            }
                                            {/* :
                                                             <></>

                                             } */}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                autoFocus
                                                onClick={() => {
                                                    CloseHandleClick();
                                                }}
                                                color="primary"
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* 
                    <ReactReader
                        url={book1}
                        location={location}
                        locationChanged={epubcifi => epubLocation(epubcifi)}
                        style={{ height: 800, alignItems: "center" }}
                    /> */}



                                    {/* POINTERS */}

                                    {/* <div style={{ position: "absolute", zIndex: 2, height: 20, backgroundColor: "transparent", width: "50%" }}>

                        {
                            (leftPage != 0 && rightPage != 1) ?
                                <div style={{ position: "absolute", zIndex: 2, height: 20, backgroundColor: "transparent", width: "50%" }}>

                                    <Draggable
                                        axis="x"
                                        handle=".handle"
                                        defaultPosition={{ x: 200, y: 0 }}
                                        position={null}
                                        grid={[25, 25]}
                                        scale={1}
                                        onStart={handleStartPointer}
                                        onDrag={eventLoggerPointer}
                                        onStop={handleStopPointer}
                                    // style={{ zIndex: 2, position: "absolute" }}
                                    >
                                        <div>

                                            <div className="handle">
                                                {showTipPoint ?
                                                    <Tooltip title={pointerText} placement="right">
                                                        <KeyboardArrowDownOutlinedIcon style={{ fontSize: 40 }} />
                                                    </Tooltip>
                                                    :
                                                    <KeyboardArrowDownOutlinedIcon style={{ fontSize: 40 }} />
                                                }
                                            </div>
                                        </div>
                                    </Draggable>
                                </div>
                                :
                                <></>

                        } */}

                                    {/* {
                            (leftPage != 0 && rightPage != 1) ?
                                <div style={{ position: "absolute", zIndex: 2, height: 20, backgroundColor: "transparent", width: "50%" }}>

                                    <Draggable
                                        axis="x"
                                        handle=".handle"
                                        defaultPosition={{ x: 800, y: 0 }}
                                        position={null}
                                        grid={[25, 25]}
                                        scale={1}
                                        onStart={handleStartPointer1}
                                        onDrag={eventLoggerPointer1}
                                        onStop={handleStopPointer1}
                                    // style={{ zIndex: 2, position: "absolute" }}
                                    >
                                        <div>

                                            <div className="handle">
                                                {showTipPoint1 ?
                                                    <Tooltip title={pointerText} placement="right">
                                                        <KeyboardArrowDownOutlinedIcon style={{ fontSize: 40 }} />
                                                    </Tooltip>
                                                    :
                                                    <KeyboardArrowDownOutlinedIcon style={{ fontSize: 40 }} />
                                                }
                                            </div>
                                        </div>
                                    </Draggable>
                                </div>
                                :
                                <></>

                        }

                    </div> */}






                                    {/* 
                    <TurnBook
                        getPageNum={(left, right) => {
                            updatePages(left, right);
                        }}
                        bookPages={bookPage}
                    /> */}
                                </div>

                            </div>

                        </>
                        :
                        <CircularProgress color="secondary" />
                    }
                </div>


                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {showMapButton ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: 10,
                            }}
                            onClick={() => {
                                postEbookMapContent(
                                    bookId,
                                    fabData.id,
                                    fabData.subject_id,
                                    xPos,
                                    yPos,
                                    location,
                                    location,
                                    fabData.content_location
                                );
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    fontFamily: "Poppins, Helvetica, sans-serif",
                                    textTransform: "none",
                                }}
                            >
                                Map content
              </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                </div> */}

                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                >
                    <Alert onClose={handleSnackClose} severity="success">
                        Content sucessfully mapped..
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={delSnackOpen}
                    autoHideDuration={6000}
                    onClose={handleDelSnackClose}
                >
                    <Alert onClose={handleDelSnackClose} severity="success">
                        Content successfully deleted..
                    </Alert>
                </Snackbar>
            </main>
        </div >
    );
}

export default BookReaderScreen;

/**
 * @project kgtopg - schooler - partner
 * publisher data services
 */
import API from "./http";
import axios from "axios";
import qs from "querystring";
import Constants from "../resource/Constants";
import Cookie from 'js-cookie';
import { data } from "jquery";

export default {

    async getChapTops(chapIds) {

        console.log("getChapTops() api is getting called")
        try {

            let url = Constants.Application.PLATFORM_END_URL;
            url += "/get-chapter-topics/";
            console.log("getChapTops api url", url)

            const data = {
                chapter_ids: chapIds
            }

            const resp = await fetch(url, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            });
            const resp2 = await resp.json();

            console.log("getChapTops api resp2", resp2);

            if (resp2) {

                return resp2;
            }

            else return false;
        } catch (ex) {
            return false;
        }
    },

}

import React, { useEffect, useState } from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import "../../css/sidebar.css"
import { Link } from 'react-router-dom'
import { ExitToApp, ExitToAppOutlined, LaptopWindows, SettingsOutlined } from '@material-ui/icons';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { CircularProgress, Divider, Input } from '@material-ui/core';
import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import image from "../../assets/bus.png"
import FilterResults from 'react-filter-search';
import SearchIcon from '@material-ui/icons/Search';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import { listEbookContentGet } from "../../redux/actions/ebookContentAction"
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PUBLISHERAPI from '../../http/publisher'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import Cookie from 'js-cookie';
import "../../css/sidebar.css"
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, AlertTitle } from '@material-ui/lab';
import Constants from "../../resource/Constants";

import InputLabel2 from '@mui/material/InputLabel';
import MenuItem2 from '@mui/material/MenuItem';
import FormHelperText2 from '@mui/material/FormHelperText';
import FormControl2 from '@mui/material/FormControl';
import Select2 from '@mui/material/Select';

import ListSubheader2 from '@mui/material/ListSubheader';
import List2 from '@mui/material/List';
import ListItemButton2 from '@mui/material/ListItemButton';
import ListItemIcon2 from '@mui/material/ListItemIcon';
import ListItemText2 from '@mui/material/ListItemText';
import Collapse2 from '@mui/material/Collapse';
import InboxIcon2 from '@mui/icons-material/MoveToInbox';
import DraftsIcon2 from '@mui/icons-material/Drafts';
import SendIcon2 from '@mui/icons-material/Send';
import ExpandLess2 from '@mui/icons-material/ExpandLess';
import ExpandMore2 from '@mui/icons-material/ExpandMore';
import StarBorder2 from '@mui/icons-material/StarBorder';
import Snackbar from '@mui/material/Snackbar';
import Alert2 from '@mui/material/Alert';
import AlertTitle2 from '@mui/material/AlertTitle';

import Chip2 from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactComponent as RippleIcon } from '../../assets/Ripple38.svg'

import { listChpGet } from "../../redux/actions/chapterAction";
import { listTopGet, listTopReset } from "../../redux/actions/topicAction";

import PLATFORMAPI from "../../http/platformapi";

const TAG = 'PublisherListSideBar.js';

const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const useStyles3 = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    customWidth: {
        maxWidth: 500,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
}));

const longText = `
Click on the Thumbnail card to show the play  button on ebook 
`;
function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell style={{
                    width: '5%'
                }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.topic_title}
                </TableCell>
                {/* <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography> */}
                            <Table size="small" aria-label="purchases">
                                {/* <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead> */}
                                <TableBody>
                                    {row.sub_topics.map((subRow, idx) => (
                                        <TableRow key={idx}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => { props.getCardData(subRow) }}
                                        >
                                            {/* <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell> */}
                                            <TableCell
                                            >{subRow.topic_sub_name}</TableCell>
                                            {/* <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell> */}
                                            <TableCell align="right"
                                                style={{
                                                    textAlign: 'right',
                                                    width: '5%'
                                                }}>
                                                <RippleIcon style={{
                                                    float: 'right'
                                                }} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

function CollapsibleTable(props) {

    const topDetailsGet = useSelector((state) => state.topDetails);
    const { loadingTop, TopDetails, errorTop } = topDetailsGet;

    console.log('collapsibletable topics ', TopDetails)

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">

                <TableBody>
                    {!loadingTop && TopDetails && TopDetails.data 
                    && TopDetails.data.map((row, idx) => (
                        <Row key={idx} row={row}
                            getCardData={(contentDetails) => { props.getCardData(contentDetails) }} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const Row2 = () => {
    return (<></>);
}


function CollapsibleTable2(props) {

    const topDetailsGet = useSelector((state) => state.topDetails);
    const { loadingTop, TopDetails, errorTop } = topDetailsGet;

    console.log('TopDetails are ', TopDetails);
    return (
        TopDetails && TopDetails.data && TopDetails.data.length ?
            (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 20
                        }}>
                        <Typography
                            variant="h4">
                            Enter the exact media URL in the Topic text box
                        </Typography>

                        <Chip2 label="Added 5/10" variant="outlined" size="large"
                            style={{
                                fontSize: 20
                            }} />

                        <IconButton aria-label="delete" size="large"
                            onClick={() => props.onCloseClicked(props.anchor, true)}>
                            <CancelIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                {/* <TableRow>
                    <TableCell style={{
                      width: '5%'
                    }} />
                    <TableCell
                    >
                    
                    </TableCell>
              
                    <TableCell align="center"
                      style={{
                        width: '50%'
                      }}>
                      &nbsp;
                    </TableCell>
                  </TableRow> */}
                            </TableHead>
                            <TableBody>
                                {TopDetails && TopDetails.data && TopDetails.data.map((row) => (
                                    <Row key={row.name} row={row} onURLChanged={(e, sIdx) => props.onURLChanged(e, sIdx)} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div
                        style={{
                            display: "flex", justifyContent: "space-between",
                            padding: 20, marginTop: 20, width: '100%'
                        }}
                    >
                        {/* <Link to="/publisher/mapping" style={{ textDecoration: "none", color: "#fff" }}> */}
                        <Button
                            variant="contained"
                            onClick={() => props.onCloseClicked(props.anchor, true)}
                        >Cancel</Button>
                        <Button
                            disabled={props.singleUploadDisabled}
                            variant="contained"
                            color="primary"
                            style={{
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                textTransform: "none",
                                // width: '100%'
                                // backgroundColor: "#3699FF",
                                // color: '#FFFFFF'
                            }}
                            onClick={() => {
                                props.handleSingleContentUpload();
                            }}
                        >
                            Save
                        </Button>
                        <Snackbar open={props.snackOpen} autoHideDuration={6000} onClose={props.handleSnackClose}>
                            <Alert onClose={props.handleSnackClose} severity="warning">
                                Something is not right with your input!
                            </Alert>
                        </Snackbar>

                        {/* </Link> */}
                    </div>
                </>
            )
            :
            <div
                style={{
                    margin: 20
                }}>
                <Alert2 severity="info">
                    <AlertTitle2>No Chapter selected!</AlertTitle2>
                    You have to select a chapter to choose topics and map URL to them - <strong>choose a chapter on the left panel!</strong>
                </Alert2>
            </div>
    );
}


// const PublisherListCard = (props) => {

//     const classes = useStyles1();
//     const [checked, setChecked] = React.useState([1]);
//     const [showcontentCard, setShowContentCard] = useState(false)
//     const [provId, setProvId] = useState("")
//     const [provDetails, setProvDetails] = useState("")
//     const [chap, setChap] = useState('');
    
//     const { selCont } = props

//     const chpDetailsGet = useSelector((state) => state.chpDetails);
//     const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

//     const topDetailsGet = useSelector((state) => state.topDetails);
//     const { loadingTop, TopDetails, errorTop } = topDetailsGet;
//     const bookDetails = props.bookDetails;
//     const [chapterName, setChapterName] = useState((chapDetails && chapDetails.data && chapDetails.data.length) ? chapDetails.data[0].chapter_title : 'Select Chapter');

//     console.log('bookdetails in leftcard ', bookDetails);
//     console.log('chapdetails in leftcard ', chapDetails);
//     console.log('topicdetails in leftcard ', TopDetails);

//     const handleChangeChap = (event) => {
//         console.log('event val change chapter ', event.target.value)
//         setChap(event.target.value);
//     };

//     const handleChpChange = (event, chaptername) => {
//         console.log("handleChpChange called");
//         console.log(`handleChpChange new chapter ${event}`);

//         const name = event.target.name;
//         const value = event.target.value;
//         console.log("handleChpChange chapter name ", chaptername);
//         console.log('handlechpchange chapter id ', value);
//         setChap(value);

//         dispatch(listTopGet(value));
//     };

//     const dispatch = useDispatch();

//     const handleToggle = (value) => () => {
//         const currentIndex = checked.indexOf(value);
//         const newChecked = [...checked];

//         if (currentIndex === -1) {
//             newChecked.push(value);
//         } else {
//             newChecked.splice(currentIndex, 1);
//         }

//         setChecked(newChecked);
//     };

//     const prov = props.contentData;
//     const SendProvDataToList = (provData) => {
//         props.providerData(provData)
//     }

//     const UpdateProvDetails = (status, id, provData) => {
//         SendProvDataToList(provData)
//         setProvDetails(provData)
//         setShowContentCard(status)
//         setProvId(id)
//     }


//     const sendContDatatoParent = (contData) => {
//         props.getContentDatafromPubList(contData)
//     }


//     const ShowContentCard = (status) => {
//         setShowContentCard(status)
//         props.showProvName(status)
//     }

//     const resetContentSearch = () => {
//         SendProvDataToList(selCont)
//         setProvDetails(selCont)
//         // setShowContentCard(status)

//         if (selCont)
//             setProvId(selCont.id)
//     }

//     const getChapters = () => {
//         console.log('get chapters called...')
//         console.log('get chapters called chapterName ', chapterName)
//         console.log('get chapters called chap ', chap)
//         if (bookDetails) {
//             console.log('get chapters called bookDetails ', bookDetails)
//             const instId = bookDetails.institute_type_id;
//             const boardId = bookDetails.institute_board_id;
//             const subjectId = bookDetails.subject_id;

//             dispatch(listChpGet(subjectId, boardId));
//         }
//     }

//     const checkChapter = () => {
//         if(!chap) {
//             dispatch(listTopReset())
//         }
//     }

//     useEffect(() => {
//         resetContentSearch()

//         // get chapters list
//         getChapters();

//         // verify chapter to unset topics
//         checkChapter()
//     }, {})

//     // const ProviderList = (props) => {


//     //     return (
//     //         <List dense className={classes.root}>
//     //             {props.contentData && props.contentData.length ?
//     //                 props.contentData.map((value) => {
//     //                     console.log("conetnt Data at Publisherlist card  ", value)

//     //                     return (

//     //                         !showcontentCard ?
//     //                             <div >

//     //                                 <ListItem key={value} button onClick={() => { props.showCard(true, value.id, value) }}>
//     //                                     <ListItemAvatar>
//     //                                         <Avatar
//     //                                             alt={value.prov_name_text}
//     //                                             src={value.prov_cover_url}
//     //                                         />
//     //                                     </ListItemAvatar>
//     //                                     <ListItemText id={value.id} primary={value.prov_name_text} />
//     //                                     <ListItemSecondaryAction>
//     //                                         <ChevronRightOutlinedIcon />
//     //                                     </ListItemSecondaryAction>
//     //                                 </ListItem>
//     //                                 <div style={{ display: "flex", justifyContent: "center" }}>
//     //                                     <Divider
//     //                                         style={{
//     //                                             width: "100%",
//     //                                             display: "flex",
//     //                                             justifyContent:
//     //                                                 "center",
//     //                                             alignSelf: "center"
//     //                                         }} />
//     //                                 </div>
//     //                             </div>
//     //                             :
//     //                             <></>




//     //                     );
//     //                 })
//     //                 :

//     //                 <Alert severity="warning">No content providers found!</Alert>
//     //             }
//     //         </List>
//     //     )

//     // }

//     return (
//         <div style={{ maxHeight: "100vh" }}>
//             {/* {
//                 showcontentCard ?
//                     <ProviderList contentData={prov} showCard={(status, id, provData) => UpdateProvDetails(status, id, provData)} />
//                     :
//                     <DisplayCard
//                         contentData={prov}
//                         id={provId}
//                         showProvList={(status) => { ShowContentCard(status) }}
//                         getCardData={(contentDetails) => { sendContDatatoParent(contentDetails) }}
//                     />

//             } */}

// {/* 

//             <div
//                 style={{
//                     display: 'none',
//                     flexDirection: 'column',
//                     justifyContent: 'center',
//                     alignItems: 'center'
//                 }}>
//                 {chpDetailsGet ? (
//                     loadingChap && chpDetailsGet ? (
//                         <div style={{
//                             display: "flex", justifyContent: "center",
//                             width: '90%'
//                         }}>
//                             <FormControl variant="standard" className={classes.formControl}
//                                 style={{
//                                     width: '100%'
//                                 }}>
                                
//                                 <InputLabel
//                                     id="demo-simple-select-outlined-label"
//                                     style={{
//                                         fontSize: 13,
//                                         color: "#464E5F",
//                                         fontFamily: "Poppins, Helvetica, sans-serif",
//                                     }}
//                                 >
//                                     Chapter
//                                 </InputLabel>
//                                 <Select
//                                     labelId="demo-simple-select-outlined-label"
//                                     id="demo-simple-select-outlined"
//                                     value={chap}
//                                     onChange={handleChpChange}
//                                     label="Standard/Stream"
//                                     style={{
//                                         fontSize: 13,
//                                         color: "#464E5F",
//                                         fontFamily: "Poppins, Helvetica, sans-serif",
//                                     }}
//                                     fullWidth
//                                 >

//                                     <MenuItem
//                                         style={{
//                                             fontSize: 13,
//                                             color: "#464E5F",
//                                             fontFamily: "Poppins, Helvetica, sans-serif",
//                                         }}
//                                     >
//                                         Please Select Subject
//                                     </MenuItem>
//                                 </Select>
                                
//                             </FormControl>
//                         </div>
//                     ) : (
//                         <div style={{
//                             display: "flex", justifyContent: "center",
//                             width: '90%'
//                         }}>
//                             <FormControl variant="standard" className={classes.formControl}
//                                 style={{
//                                     width: '100%'
//                                 }}>
//                                 <InputLabel
//                                     id="demo-simple-select-outlined-label"
//                                     style={{
//                                         fontSize: 13,
//                                         color: "#464E5F",
//                                         fontFamily: "Poppins, Helvetica, sans-serif",
//                                     }}
//                                 >
//                                     Chapter
//                                 </InputLabel>
//                                 <Select
//                                     labelId="demo-simple-select-outlined-label"
//                                     id="demo-simple-select-outlined"
//                                     value={chap}
//                                     onChange={handleChpChange}
//                                     label="Standard/Stream"
//                                     style={{
//                                         fontSize: 13,
//                                         color: "#464E5F",
//                                         fontFamily: "Poppins, Helvetica, sans-serif",
//                                     }}
//                                     fullWidth
//                                 >
//                                     {loadingChap && chpDetailsGet ? (
//                                         console.log("loading trueeee")
//                                     ) : chapDetails.data && chapDetails.data.length != 0 ? (
//                                         chapDetails.data.map((list, index) => {
//                                             return (
//                                                 <MenuItem
//                                                     key={index}
//                                                     value={list.chapter_id}
//                                                     style={{
//                                                         fontSize: 13,
//                                                         color: "#464E5F",
//                                                         fontFamily: "Poppins, Helvetica, sans-serif",
//                                                     }}
//                                                     onClick={() => {
//                                                         setChapterName(list.chapter_title);
//                                                     }}
//                                                 >
//                                                     {list.chapter_title}
//                                                 </MenuItem>
//                                             );
//                                         })
//                                     ) : (
//                                         <MenuItem
//                                             style={{
//                                                 fontSize: 13,
//                                                 color: "#464E5F",
//                                                 fontFamily: "Poppins, Helvetica, sans-serif",
//                                             }}
//                                         >
//                                             Please Select Institute Type
//                                         </MenuItem>
//                                     )}
//                                 </Select>
                                
//                             </FormControl>
//                         </div>
//                     )
//                 ) : (
//                     <div style={{
//                         display: "flex", justifyContent: "center",
//                         width: '90%'
//                     }}>
//                         <FormControl variant="standard" className={classes.formControl}
//                             style={{
//                                 width: '100%'
//                             }}>
//                             <InputLabel
//                                 id="demo-simple-select-outlined-label"
//                                 style={{
//                                     fontSize: 13,
//                                     color: "#464E5F",
//                                     fontFamily: "Poppins, Helvetica, sans-serif",
//                                 }}
//                             >
//                                 Chapter
//                             </InputLabel>
//                             <Select
//                                 labelId="demo-simple-select-outlined-label"
//                                 id="demo-simple-select-outlined"
//                                 value={chap}
//                                 onChange={handleChpChange}
//                                 label="Standard/Stream"
//                                 style={{
//                                     fontSize: 13,
//                                     color: "#464E5F",
//                                     fontFamily: "Poppins, Helvetica, sans-serif",
//                                 }}
//                                 fullWidth
//                             >
//                                 <MenuItem
//                                     style={{
//                                         fontSize: 13,
//                                         color: "#464E5F",
//                                         fontFamily: "Poppins, Helvetica, sans-serif",
//                                     }}
//                                 >
//                                     No Chapters
//                                 </MenuItem>
//                             </Select>
                            
//                         </FormControl>
//                     </div>
//                 )}
//             </div> */}

//             <FormControl2 sx={{ m: 0, mt: 2, p: 1, minWidth: '100%' }}>
//                 <InputLabel2 id="demo-simple-select-helper-label">{chapterName}</InputLabel2>
//                 <Select2
//                     labelId="demo-simple-select-helper-label"
//                     id="demo-simple-select-helper"
//                     // defaultValue={chap}
//                     value={chap}
//                     label="Age"
//                     onChange={handleChpChange}
//                 >


// {/* 
//                     <MenuItem2 value="">
//                         <em>None</em>
//                     </MenuItem2>
//                     <MenuItem2 value={10}>Ten</MenuItem2>
//                     <MenuItem2 value={20}>Twenty</MenuItem2>
//                     <MenuItem2 value={30}>Thirty</MenuItem2> */}

//                     {loadingChap && chpDetailsGet ? (
//                         console.log("loading trueeee")
//                     ) : chapDetails.data && chapDetails.data.length != 0 ? (
//                         chapDetails.data.map((list, index) => {
//                             return (
//                                 <MenuItem
//                                     key={index}
//                                     value={list.chapter_id}
//                                     style={{
//                                         fontSize: 13,
//                                         color: "#464E5F",
//                                         fontFamily: "Poppins, Helvetica, sans-serif",
//                                     }}
//                                     onClick={() => {
//                                         setChapterName(list.chapter_title);

//                                         // get the topics here
//                                     }}
//                                 >
//                                     {list.chapter_title}
//                                 </MenuItem>
//                             );
//                         })
//                     ) : (
//                         <MenuItem
//                             style={{
//                                 fontSize: 13,
//                                 color: "#464E5F",
//                                 fontFamily: "Poppins, Helvetica, sans-serif",
//                             }}
//                         >
//                             No Chapters found!
//                         </MenuItem>
//                     )}

//                 </Select2>
//                 <FormHelperText2 style={{
//                     color: 'black'
//                 }}>Select a chapter, next select topic below and drag the ripple icon to the desired point, where the video is going to play.</FormHelperText2>
//             </FormControl2>

//             {!loadingTop && TopDetails && TopDetails.data ?
//             <CollapsibleTable
//                 getCardData={(contentDetails) => { sendContDatatoParent(contentDetails) }}

//             />
//             :
//             <Alert2  severity="warning">
//                 <AlertTitle2>Please select a chapter above!</AlertTitle2>
//             </Alert2>
//             }
//         </div>
//     );
// }




// const DisplayCard = (props) => {
//     const classes = useStyles3();
//     const [title, setTitle] = useState("")
//     const [searchResults, setSearchResults] = useState([])


//     let provDetails = props.contentData
//     let id = props.id

//     console.log("props at displayCard ", id, provDetails)

//     const handleTitleChange = (event) => {
//         console.log(`new title ${event}`)
//         const { name, value } = event.target
//         setTitle(value)
//     }


//     useEffect(() => {
//         let temp = [];

//         provDetails.map((details, index) => {
//             details.id == id && details.contents && details.contents.length != 0 ?
//                 details.contents.map((list, index) => {
//                     temp.push(list)
//                 })
//                 :
//                 <></>
//         })


//         // if(temp && temp.length > 0) {
//         const results = (title) ? temp.filter(name => (name.content_name.toLowerCase().includes(title.toLowerCase()) || name.tags.toLowerCase().includes(title.toLowerCase()))) : []
//         console.log("results", results)
//         setSearchResults(results)
//         // }

//         console.log("search Resust at state", searchResults)
//     }, [title])

//     return (
//         <div>
//             <div >
//                 <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', marginTop: 10, width: '100%' }}>

//                     <TextField
//                         id="outlined-basic"
//                         label="Search Content"
//                         variant="outlined"
//                         defaultValue={title}
//                         onChange={handleTitleChange}
//                         autoComplete="nope"
//                         size="small"
//                         style={{
//                             // width: 250,
//                             fontFamily: "Poppins, Helvetica, sans-serif",
//                             display: "inline-block",
//                             // lineHeight: "140%"
//                             width: '80%'

//                         }}

//                     />


//                     {/* <ClearOutlinedIcon onClick={() => { props.showProvList(false) }} style={{ cursor: "pointer" }} /> */}
//                 </div>

//                 {/* <div style={{ display: "flex", justifyContent: "left", marginLeft: 20 }}>
//                     <Typography>
//                         <span
//                             style={{
//                                 fontSize: 12,
//                                 color: "#464E5F",
//                                 marginLeft: 5,
//                                 fontFamily: "Poppins, Helvetica, sans-serif",
//                             }}
//                         >
//                             Search by <span
//                                 style={{
//                                     color: "#464E5F",
//                                     fontWeight: "600",
//                                     fontFamily: "Poppins, Helvetica, sans-serif",
//                                     fontSize: 12
//                                 }}>
//                                 Content Name
//                             </span>
//                         </span>
//                     </Typography>

//                 </div> */}
//             </div>



//             {/* 

//             <div style={{ display: "flex", justifyContent: "flex-end", margin: 10 }}>
//                 <ClearOutlinedIcon onClick={() => { props.showProvList(false) }} style={{ cursor: "pointer" }} />
//             </div> */}

//             <div className="contentScroll" >
//                 {
//                     // provDetails.map((details, index) => {
//                     //     console.log("details at display card =============", details, id)
//                     //     return (

//                     //         details.id == id && details.contents && details.contents.length != 0 ?

//                     //             details.contents.map((list, index) => {
//                     //                 return (
//                     //                     <Card style={{ width: 270, height: 150, margin: 10, justifyContent: "center", }} key={index} onClick={() => { props.getCardData(list) }}>
//                     //                         <CardMedia
//                     //                             component="img"
//                     //                             alt={list.content_name}
//                     //                             height="100"
//                     //                             image={list.image_cover_url}
//                     //                             title={list.content_name}
//                     //                         />

//                     //                         <CardContent>
//                     //                             <Typography
//                     //                                 gutterBottom
//                     //                                 variant="h5"
//                     //                                 component="h2"
//                     //                                 style={{
//                     //                                     fontFamily: "Poppins, Helvetica, sans-serif",
//                     //                                     fontSize: 14,
//                     //                                 }}
//                     //                             >
//                     //                                 {list.content_name}
//                     //                             </Typography>
//                     //                         </CardContent>
//                     //                     </Card>

//                     //                 )
//                     //             })



//                     //             :
//                     //             <></>
//                     //     )
//                     // })

//                     (searchResults && searchResults.length) ?
//                         searchResults.map((list, index) => {
//                             return (
//                                 <Tooltip title={longText} placement="bottom">
//                                     <Card style={{ width: 270, height: 150, margin: 10, justifyContent: "center", }} key={index} onClick={() => { props.getCardData(list) }}>
//                                         <CardMedia
//                                             component="img"
//                                             alt={list.content_name}
//                                             height="100"
//                                             image={list.image_cover_url}
//                                             title={list.content_name}
//                                         />

//                                         <CardContent>
//                                             <Typography
//                                                 gutterBottom
//                                                 variant="h5"
//                                                 component="h2"
//                                                 style={{
//                                                     fontFamily: "Poppins, Helvetica, sans-serif",
//                                                     fontSize: 14,
//                                                 }}
//                                             >
//                                                 {list.content_name}
//                                             </Typography>
//                                         </CardContent>
//                                     </Card>
//                                 </Tooltip>
//                             )
//                         })
//                         :
//                         <div style={{
//                             marginTop: 20
//                         }}>
//                             <Alert severity="info">
//                                 <AlertTitle>No queries made</AlertTitle>
//                                 Please use the search field to filter appropriate content — <strong>use the right keyword!</strong>
//                             </Alert>
//                         </div>

//                 }
//             </div>

//             <div className="contnetScroll">
//                 {


//                 }

//             </div>


//         </div>
//     )
// }

const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


// function CheckboxListSecondary(props) {
//     const classes = useStyles2();
//     const [checked, setChecked] = React.useState([]);
//     const { contentData } = props;

//     console.log("checked list ", checked)

//     const handleSelectProv = () => {

//     }

//     const handleToggle = (e, value) => () => {
//         console.log("values at checkedlist", value)
//         const currentIndex = checked.indexOf(value);
//         const newChecked = [...checked];

//         if (currentIndex === -1) {
//             newChecked.push(value);
//             props.getSelectedProv(value)
//         } else {
//             newChecked.splice(currentIndex, 1);
//         }

//         setChecked(newChecked);


//     };

//     const data = props && props.ContentProviders ? props.ContentProviders : ""
//     let contentIds = []
//     if (contentData && contentData.length) {
//         contentData.map(cont => {
//             contentIds.push(cont.id)
//         })
//     }

//     console.log("providers list at check box 2222222222222222222222", props.ContentProviders)
//     console.log(`contentData at check box ${JSON.stringify(contentData)}`)
//     console.log(`contentIds ${JSON.stringify(contentIds)}`)

//     const role = Cookie.get('kgtopg.partner.user.role')

//     return (
//         <List dense className={classes.root} style={{ width: 350 }}>
//             {data.map((value) => {


//                 const contProvId2 = parseInt(value.id)
//                 const contProvId = parseInt(Cookie.get('kgtopg.partner.cont.id'))

//                 let sameFound = false;
//                 let dualRole = false;

//                 if (contProvId == contProvId2)
//                     sameFound = true;
//                 if (role == "CONTENT_PROVIDER AND PUBLISHER")
//                     dualRole = true;



//                 const labelId = `checkbox-list-secondary-label-${value}`;
//                 let selected = false

//                 if (contentIds.indexOf(value.id) > -1)
//                     selected = true

//                 // check if dual-role, only show his own provider
//                 if (dualRole) {
//                     // if (sameFound) {

//                     return (
//                         <ListItem key={value} button
//                             selected={selected}
//                             onClick={(e) => { props.savePubContProv(e, value.id) }}
//                         >
//                             <ListItemAvatar>
//                                 <Avatar
//                                     alt={value.prov_name_text}
//                                     src={value.prov_cover_url}
//                                 />
//                             </ListItemAvatar>
//                             <ListItemText id={labelId} primary={value.prov_name_text} />
//                             <ListItemSecondaryAction>
//                                 {/* <Checkbox */}
//                                 <Radio
//                                     edge="end"
//                                     // onChange={(e) => handleToggle(e, value)}
//                                     onChange={(e) => {
//                                         props.savePubContProv(e, value.id)
//                                     }}
//                                     // checked={checked.indexOf(value) !== -1}
//                                     checked={selected}
//                                     inputProps={{ 'aria-labelledby': labelId }}
//                                 />
//                                 {/* <DeleteOutlinedIcon color="secondary" /> */}
//                             </ListItemSecondaryAction>
//                         </ListItem>
//                     );
//                     // }
//                 } else {
//                     // show all
//                     return (
//                         <ListItem key={value} button
//                             selected={selected}
//                             onClick={(e) => { props.savePubContProv(e, value.id) }}
//                         >
//                             <ListItemAvatar>
//                                 <Avatar
//                                     alt={value.prov_name_text}
//                                     src={value.prov_cover_url}
//                                 />
//                             </ListItemAvatar>
//                             <ListItemText id={labelId} primary={value.prov_name_text} />
//                             <ListItemSecondaryAction>
//                                 {/* <Checkbox */}
//                                 <Radio

//                                     edge="end"
//                                     // onChange={(e) => handleToggle(e, value)}
//                                     onChange={(e) => {
//                                         props.savePubContProv(e, value.id)
//                                     }}
//                                     // checked={checked.indexOf(value) !== -1}
//                                     checked={selected}
//                                     inputProps={{ 'aria-labelledby': labelId }}
//                                 />
//                                 {/* <DeleteOutlinedIcon color="secondary" /> */}
//                             </ListItemSecondaryAction>
//                         </ListItem>
//                     );
//                 }
//             })}
//         </List>
//     );
// }




function PublisherListSideBar(props) {


    const chpDetailsGet = useSelector((state) => state.chpDetails);
    const { loadingChap, chapDetails, errorChp } = chpDetailsGet;

    const topDetailsGet = useSelector((state) => state.topDetails);
    const { loadingTop, TopDetails, errorTop } = topDetailsGet;
    const bookDetails = props.bookDetails;

    const [data, setData] = useState([]);
    const [value, setValue] = useState("");
    const [search, setSearch] = useState("")
    const [showContent, setShownContent] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [book_id, setBook_id] = useState("")
    const [contData, setContData] = useState("")
    const [title, setTitle] = useState("")
    const [pubId, setPubId] = useState(2)
    const teachPrev = (props.teachPrev != null) ? props.teachPrev : false
    const bookId2 = (props.bookId != null) ? props.bookId : 0
    const [prov, setProvider] = useState("")
    const [provStatus, setProvStatus] = useState("")
    const [uploadMultiple, setUploadMultiple] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [chapsData, setChapsData] = useState(null);

    const [open2, setOpen2] = React.useState(false);

    const handleClick2 = () => {
        setOpen2(true);
    };

    const handleClose2 = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen2(false);
    };

    // render time
    let sChapNames2 = [];
    let sTopNames2 = [];
    let sTopIds2 = [];
    let sTopIndices2 = [];
    let sPageNos2 = [];
    let sPageENos2 = [];
    let sChapIndices2 = [];
    if(chapDetails && chapDetails.data && chapDetails.data.length > 0) {
        chapDetails.data.forEach((chp2) => {
            sChapNames2.push(chp2.pub_chapter_name);
            sTopNames2.push([]); // empty list -> each item a list of topics under this chapter
            sPageNos2.push(0);
            sPageENos2.push(0);
            sTopIds2.push([]);
            sTopIndices2.push([]);
            sChapIndices2.push(chp2.chapter_index);
        });
    }

    const [chapNames, setChapNames] = useState(sChapNames2);
    const [chapIndices, setChapIndices] = useState(sChapIndices2);
    const [topNames, setTopNames] = useState(sTopNames2);
    const [topIds, setTopIds] = useState(sTopIds2);
    const [topIndices, setTopIndices] = useState(sTopIndices2);
    const [pageNos, setPageNos] = useState(sPageNos2);
    const [pageENos, setPageENos] = useState(sPageENos2);

    const handlePageNoChange = (e, index) => {
        const newPageNos = [...pageNos];
        newPageNos[index] = e.target.value;
        console.log('handlePageNoChange newPageNos', newPageNos);
        
        setPageNos(newPageNos);
    };

    const handlePageENoChange = (e, index) => {
        const newPageENos = [...pageENos];
        newPageENos[index] = e.target.value;
        console.log('handlePageNoChange newPageENos', newPageENos);
        
        setPageENos(newPageENos);
    };

    const handleChapNameChange = (e, index) => {
        const newChapNames = [...chapNames];
        newChapNames[index] = e.target.value;
        setChapNames(newChapNames);
    }

    const handleChapIndexChange = (e, index) => {
        const newChapIndices = [...chapIndices];
        newChapIndices[index] = e.target.value;
        setChapIndices(newChapIndices);
    }

    const handleTopNameChange = (e, index, chapIdx) => {
        const newTopNames = [...topNames];
        // console.log(TAG, 'handleTopNameChange newTopNames before', newTopNames);
        // verify if index exists
        if(index < newTopNames[chapIdx].length) // update
            newTopNames[chapIdx][index] = e.target.value;
        // otherwise add
        else
            newTopNames[chapIdx].push(e.target.value);

        // console.log(TAG, 'handleTopNameChange newTopNames after', newTopNames);
        setTopNames(newTopNames);
    }


    const handleTopIndexChange = (e, index, chapIdx) => {
        const newTopIndices = [...topIndices];
        // console.log(TAG, 'handleTopNameChange newTopNames before', newTopNames);
        // verify if index exists
        if(index < newTopIndices[chapIdx].length) // update
            newTopIndices[chapIdx][index] = e.target.value;
        // otherwise add
        else
            newTopIndices[chapIdx].push(e.target.value);

        // console.log(TAG, 'handleTopNameChange newTopNames after', newTopNames);
        setTopIndices(newTopIndices);
    }

    // console.log(`PublisherListSideBar bookDetails ${JSON.stringify(bookDetails)}`)
    // console.log('PublisherListSideBar chapNames', chapNames);

    const [selProv, setSelProv] = useState("")

    const savePubContProv = async (e, contProvId, subId) => {
        // const pubId = 5;
        const { name, value, checked } = e.target
        console.log(`checkeddd ${checked}`)
        console.log("savePubContent id at publisherListBar", contProvId)
        // only if checked
        if (checked) {

            console.log(`now subscribing ${contProvId}..`)
            const body = {
                cont_prov_id: contProvId,
                pub_id: pubId,
                subject_id: bookDetails.id
            }
            await PUBLISHERAPI.savePubContProv(body);
        } else {
            // remove content provider
            console.log(`now unsubscribing ${contProvId}`)
            const body = {
                id: contProvId,
                pub_id: pubId,
            }

            await DeleteProv(body);

        }
    }

    const DeleteProv = async (provDet) => {
        const query = new URLSearchParams(window.location.search)
        console.log("-------------------------", query.get('book_id'))

        const book_id = query.get('book_id')
        const response = await PUBLISHERAPI.DelContentProvider(provDet);

        if (response) {
            getEbookContent(book_id)
        }

    }

    const handleProvChange = (event) => {
        setSelProv(event.target.value);
    };

    const UpdateProvData = (provDat) => {
        setProvider(provDat)
    }

    const UpdateProvStatus = (status) => {
        setProvStatus(!status)
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
    };


    const onTextChange = e => {
        setSearch(e.target.value)
    };

    const handleTitleChange = (event) => {
        console.log(`new title ${event}`)
        const { name, value } = event.target
        setTitle(value)
    }

    /* 
        useEffect(() => {
            
            return <></>
        }, [])
     */

    const sendContDatatoDashboard = (list, status) => {
        console.log("content Data=========", list)
        props.sendDatatoReaderScreen(list, status)
    }



    // const filteredData = top100Films.filter(content => {
    //     return content.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    // })




    const dispatch = useDispatch()

    const EbookContentGet = useSelector(state => state.ebookContent)
    const { loadingEbook, EbookContent, errorEbook } = EbookContentGet



    // console.log("EBook Content provider details at publisher list side bar", EbookContent)

    const [providers, setProviders] = useState(null)
    const [bookId, setBookId] = useState(0)

    let contentData = ""

    {
        loadingEbook && EbookContentGet != null ?
            <></>
            :
            EbookContent && EbookContent.data ?

                contentData = EbookContent.data
                :
                console.log("NO DATA AT EBOOK")

    }



    let filteredData = ""





    // {
    //     contentData ?
    //         filteredData = contentData.filter(content => {
    //             return content.content_name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    //         })

    //         :
    //         console.log("no data at ebook")
    // }


    // console.log("content Data at ebbok =====================================", filteredData)

    const getEbookContent = (book_id) => {
        dispatch(listEbookContentGet(book_id))
    }


    const getContentProviders = async () => {

        console.log("getContentProviders Function is calling...................")


        //// GET ONLY APPROVED
        const response = await PUBLISHERAPI.getContentProviders();
        console.log("content providers ++++++++++++++++++++", response.data)
        setProviders(response.data)
    }

    let mmName = 'Map Multimedia'
    let selCont = null
    const data2 = providers ? providers : []
    let contentIds = []
    let idx = -1
    let contentData2 = contentData.provider_details
    if (contentData2 && contentData2.length) {
        // console.log(`contentData2 isssss ${JSON.stringify(contentData2)}`)
        contentData2.map(cont => {
            contentIds.push(cont.id)
        })
    }

    if (data2 && data2.length) {
        // console.log(`data2 isssss ${JSON.stringify(data2)}`)
        data2.map((value, index) => {
            if (contentIds.indexOf(value.id) > -1) {
                idx = contentIds.indexOf(value.id)
                selCont = value

            }
        })

    }

    if (idx > -1 && contentData2 && contentData2.length)
        mmName = contentData2[idx].prov_name_text

    // fetch chapters and topics
    const getChapters = async () => {
        let chapIds = [];
        console.log(TAG, 'getChapters() called..');

        if(chapDetails && chapDetails.data && chapDetails.data.length) {
            chapDetails.data.forEach((chp3) => {
                chapIds.push(chp3.chapter_id);
            });


            // pull topics
            
            const resp55 = await PLATFORMAPI.getChapTops(chapIds);
            // const resp5 = await fetch(URL5);

            // const resp55 = await resp5.json();
            console.log(TAG, 'getChapters() resp55' , resp55);

            setChapsData(resp55);

            getChapters1(resp55, chapDetails.data);
        }

    }

    const getChapters1 = async (chapsTops, chapDetails1) => {

        // getChapters1();
        console.log('getChapters1 called...bookDetails ', bookDetails);
        let sChapDetails = null;
        
        if (bookDetails) {
            console.log('getChapters1 called bookDetails 2 ', bookDetails)
            const instId = bookDetails.institute_type_id;
            const boardId = bookDetails.institute_board_id;
            const subjectId = bookDetails.subject_id;
            const bookId = bookDetails.id;

            // dispatch(listChpGet(subjectId, boardId));
            
        // see if already saved
            sChapDetails = await PUBLISHERAPI.getTextFromBookData(bookId);

            
        }

        // setup arrays
        let sPageNos = [];
        let sPageENos = [];
        let sChapNames = [];
        let sTopNames = [];
        let sTopIds = [];
        let sTopIndices = [];
        let sChapIndices = [];
        // console.log('getChapters1() chapDetails', chapDetails);
        // console.log('getChapters1() chapDetails1', chapDetails1);
        // console.log('getChapters1() sChapDetails', sChapDetails);
        // console.log('getChapters1() chapsTops', chapsTops);

        let chpOrgData = [];

        // if(chapDetails && chapDetails.data && chapDetails.data.length > 0) {
            // chapDetails.data.forEach((chp, idx3) => {
        if(chapDetails1) {
            chapDetails1.forEach((chp, idx3) => {
                let chapsTopsOne = chapsTops[idx3];
                let topicListOne = chapsTopsOne.topic_list;


                let topicNamesOne = [];
                let topicIdsOne = [];
                let topicIndicesOne = [];

                if(topicListOne) {
                    topicListOne.forEach((topic, idx3) => {
                        // topicNamesOne.push(topic.topic_title);
                        topicNamesOne.push(''); // empty, not yet mapped
                        topicIdsOne.push(topic.topic_id);
                        topicIndicesOne.push(0); // first time -> not the mapped
                    });
                    
                }

                // console.log('getChapters1() topicNamesOne', topicNamesOne);
                
                sPageNos.push(0);
                sPageENos.push(0);
                sChapNames.push('');
                sTopNames.push(topicNamesOne); // empty list -> each item a list of topics under this chapter
                sTopIds.push(topicIdsOne); // for all chapters..
                sTopIndices.push(topicIndicesOne); // for all
                sChapIndices.push(0);
                chpOrgData.push({
                    chapter_id: chp.chapter_id,
                    chapter_name: chp.chapter_name,
                    index: idx3
                });
            });

        }

        console.log(TAG, 'getChapters1() sTopIds', sTopIds);
        console.log(TAG, 'getChapters1() sTopIndices', sTopIndices);

        // update existing data
        if(sChapDetails != null && sChapDetails.data && sChapDetails.data.data && sChapDetails.data.data.length) {
            console.log('getChapters1() now pre-filling..');
            // pre-fill from existing data
            sChapDetails.data.data.forEach((sChp, idx6) => {

                // see if the the index exists
                // console.log(TAG, 'getChapters1() sChapDetails sChp', sChp);
                // console.log(TAG, 'getChapters1() chpOrgData', chpOrgData);

                let found = false;
                // iterating the chpOrgData
                let foundIndex = -1;

                for (let i = 0; i < chpOrgData.length; i++) {
                    if (sChp.chapter_id === chpOrgData[i].chapter_id) {
                        foundIndex = chpOrgData[i].index;
                        break;
                    }
                }

                if (foundIndex !== -1) {
                    console.log(TAG, `getChapters1() found Chapter_id ${sChp.chapter_id} found at index ${foundIndex}.`);
                    console.log(TAG, 'getChapters1() found topics', sChp.topics);
                    found = true;
                } else {
                    console.log(TAG, `getChapters1() Chapter_id ${sChp.chapter_id} not found in the chp_list.`);
                }

                if(found) {

                    // update
                    sPageNos[foundIndex] = (sChp.page_number_start);
                    sPageENos[foundIndex] = (sChp.page_number_end);
                    sChapNames[foundIndex] = (sChp.pub_chapter_name);
                    sChapIndices[foundIndex] = (sChp.chapter_index);
                    
                    if(sChp.topics && sChp.topics.length) {
                        // some topics were added, what are they

                        // find the id of topic and filter
                        sChp.topics.forEach((sTpc, idx3) => {
                            // if found for same topic_id, use that
                            // let topIds = sTopIds[idx3];
                            // let topNames = sTopNames[idx3];
                            let tpIdx = -1;
                            let tpId = sTpc.topic_id;

                            // find this id from the stopids and pull index
                            // tpIdx = sTopIds.indexOf(tpId);
                            tpIdx = sTopIndices[foundIndex][idx3];
                            console.log(TAG, 'getChapters1() found tpId', tpId);
                            console.log(TAG, 'getChapters1() found tpIdx', tpIdx);
                            
                            if(tpIdx !== -1) {

                                // update
                                sTopNames[foundIndex][idx3] = sTpc.pub_topic_name;
                                sTopIndices[foundIndex][idx3] = sTpc.topic_index;
                            } else {
                                // do not update
                            }

                            // else use the original
                        })
                    }


                    // update the topic names here...
                    // sTopNames[foundIndex] = (['Selected Topic Name 1']); // get list from the saved info
                }
                /*
                else {
                    sPageNos.push(0);
                    sPageENos.push(0);
                    sChapNames.push(sChp.pub_chapter_name);
                    sTopNames.push([]); // empty list -> each item a list of topics under this chapter
                }
                */
            });
        }
        /*
        else {
            if(chapDetails && chapDetails.data && chapDetails.data.length > 0) {
                chapDetails.data.forEach((chp, idx3) => {
                    sPageNos.push(0);
                    sPageENos.push(0);
                    sChapNames.push(chp.chapter_title);
                    sTopNames.push([]); // empty list -> each item a list of topics under this chapter
                });

            }
        }
        */

        // console.log('getChapters1() sPageNos', sPageNos);
        // console.log('getChapters1() sPageENos', sPageENos);
        // console.log('getChapters1() sTopNames', sTopNames);
        setPageNos(sPageNos);
        setPageENos(sPageENos);
        setChapNames(sChapNames);
        setTopNames(sTopNames);
        setTopIds(sTopIds);
        setTopIndices(sTopIndices);
        setChapIndices(sChapIndices);
    }

    const handleSaveMappingClicked = async () => {
        const userId = Cookie.get('kgtopg.partner.user.id')

        console.log(TAG, 'handleSaveMappingClicked pageNos', pageNos);
        setIsSaving(true);

        let sChapNumList = [];
        const chaps = chapDetails.data;
        if(chaps && chaps.length > 0) {
            chaps.forEach((chap, idx) => {
                let sPageNo = pageNos[idx];
                let sPageENo = pageENos[idx];
                let sChapName = chapNames[idx];
                let sChapId = chap.chapter_id;
                let chapTopNames = topNames[idx];
                let chapTopIds = topIds[idx]; // never changes
                let sChapIdx = chapIndices[idx];
                let chapTopIdx = topIndices[idx];

                // build topics
                let topics5 = [];
                chapTopIds.forEach((top5, idx2) => {
                    let topic5 = {
                        topic_id: chapTopIds[idx2],
                        pub_topic_name: chapTopNames[idx2],
                        topic_index: chapTopIdx[idx2]
                    }

                    topics5.push(topic5);
                });

                console.log(TAG, 'handleSaveMappingClicked sPageNo', sPageNo);

                let chapNumObj = {
                    chapter_id: sChapId,
                    chapter_index: sChapIdx,
                    pub_chapter_name: sChapName,
                    page_number_start: sPageNo,
                    page_number_end: sPageENo,
                    topics: topics5
                }

                sChapNumList.push(chapNumObj);
            })
        }

        const body = {
            book_id: bookDetails.id,
            chapNumList: sChapNumList,
            publisher_id: userId
        }

        console.log('handleSaveMappingClicked body', body);

        const resp = await PUBLISHERAPI.saveTextFromBookData(body);
        console.log('handleSaveMappingClicked resp', resp);
        
        handleClick2();
        setIsSaving(false);
        
    }

    useEffect(() => {
        const userId = Cookie.get('kgtopg.partner.user.id')
        setPubId(userId)

        console.log(TAG, ' useEffect() called..');

        getContentProviders();
        const query = new URLSearchParams(window.location.search)
        setBook_id(query.get('book_id'))
        console.log("-------------------------", query.get('book_id'))
        getEbookContent(query.get('book_id'));

        console.log('PublisherListSideBar.js useEffect() bookDetails', bookDetails);

        // load chapters
        getChapters();

        return () => {
            // console.log("windows path", window.location.href, window.location.pathname)
            // 
        }
    }, [chpDetailsGet])


    // const AccordianCard = (props) => {

    //     return (
    //         <div>

    //             <Accordion >
    //                 <AccordionSummary
    //                     // expandIcon={<ExpandMoreIcon />}
    //                     aria-controls="panel1a-content"
    //                     id="panel1a-header"
    //                 >
    //                     <div className="dashboard" style={{ display: "flex", justifyContent: "center" }}>
    //                         <PersonOutlineOutlinedIcon />
    //                         <Typography style={{
    //                             fontSize: 15, margin: 3
    //                         }}>{props.contentname}</Typography>
    //                     </div>
    //                 </AccordionSummary>
    //                 <AccordionDetails>

    //                     <div
    //                         style={{
    //                             height: 50,
    //                             width: 270,
    //                             backgroundColor: "#fff",
    //                             border: "1px solid #e0e0e0",
    //                             borderRadius: 10,
    //                             display: "flex",
    //                             justifyContent: "center",
    //                             alignItems: "center"
    //                         }}>
    //                         <SearchIcon />
    //                         <Input
    //                             label="search content"
    //                             icon="search"
    //                             onChange={(e) => onTextChange(e)}
    //                             style={{ outlineColor: "#fff" }}
    //                             placeholder="Search Content"
    //                         />
    //                     </div>
    //                 </AccordionDetails>


    //                 <div style={{ height: "75vh", overflowY: "scroll", }}>

    //                     {/* {filteredData ?
    //                         filteredData.length != 0 ?
    //                             filteredData.map((list, index) => {
    //                                 return (
    //                                     <div onClick={() => { props.buttonstatus(list, true) }} >
    //                                         <DisplayCard list={list} key={index} />
    //                                     </div>
    //                                 )
    //                             })
    //                             :

    //                             <Card style={{ justifyContent: "center", display: "flex", width: "90%", margin: 10 }}>
    //                                 <Typography style={{ fontSize: 14, fontFamily: "Poppins, Helvetica, sans-serif" }} color="textSecondary" gutterBottom>
    //                                     No Options
    //                         </Typography>
    //                             </Card>


    //                         :
    //                         <Card style={{ justifyContent: "center", display: "flex" }}>
    //                             <Typography style={{ fontSize: 14, fontFamily: "Poppins, Helvetica, sans-serif" }} color="textSecondary" gutterBottom>
    //                                 No Options
    //                     </Typography>
    //                         </Card>

    //                     } */}


    //                 </div>
    //             </Accordion>



    //         </div>
    //     )
    // }


    // const sendDatatoReader = (list, status) => {
    //     props.sendDatatoReaderScreen(list, status)
    // }

    // const ContentProviders = (props) => {
    //     console.log("contentProviders at contentProviders", props.contentData)

    //     return (
    //         <></>
    //     )


    //     // return providers.data.map(prov => (
    //     //     <PublisherListCard prov={prov} />
    //     // ))
    // }


    // const ContentProvidersSide = () => {

    //     if (!contentData) {
    //         return (

    //             <></>
    //         )
    //     }


    //     return contentData.provider_details.map(prov => (
    //         <AccordianCard contentname={prov.prov_name_text} />
    //     ))
    // }


    // const DeleteDialog = (props) => {
    //     const { provd } = props

    //     const [open, setOpen] = React.useState(false);

    //     const handleClickOpen = () => {
    //         setOpen(true);
    //     };

    //     const handleClose = () => {
    //         setOpen(false);
    //     };


    //     const DeleteProv = async (provDet) => {
    //         const query = new URLSearchParams(window.location.search)
    //         console.log("-------------------------", query.get('book_id'))

    //         const book_id = query.get('book_id')
    //         const response = await PUBLISHERAPI.DelContentProvider(provDet);

    //         if (response) {
    //             getEbookContent(book_id)
    //         }

    //     }




    //     return (
    //         <div>

    //             <div onClick={handleClickOpen}>
    //                 <Tooltip title="Delete Provider" >
    //                     <DeleteOutlinedIcon color="secondary" />
    //                 </Tooltip>
    //             </div>

    //             <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    //                 <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
    //                 <DialogContent>
    //                     <div style={{
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         justifyContent: "center",
    //                         alignItems: "center",
    //                         alignContent: "center"
    //                     }}
    //                     >
    //                         <CloseOutlinedIcon color="secondary" style={{ fontSize: 60 }} />
    //                     </div>

    //                     <DialogContentText>
    //                         Are you Sure,Do you want to remove {provd.prov_name_text} from List ?
    //                     </DialogContentText>

    //                 </DialogContent>
    //                 <DialogActions>
    //                     <Button onClick={handleClose} color="primary">
    //                         Cancel
    //                     </Button>
    //                     <Button onClick={() => { DeleteProv(provd) }} color="primary">
    //                         Yes,Confirm
    //                     </Button>
    //                 </DialogActions>
    //             </Dialog>
    //         </div>
    //     );
    // }

    // console.log(TAG, 'MainComponent chapsData', chapsData);
    // console.log(TAG, 'MainComponent topNames', topNames);

    return (
        <div >



            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                {
                    // !prov || provStatus ?
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <ListSubheader
                            style={{
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                fontSize: 14,
                                color: "#000",
                                display: "flex",
                                justifyContent: "center"
                            }}>
                            {/* Content Providers */}
                            {/* {mmName} */}
                            {/* Map Topics to the PDF pages */}
                            Map Your Chapters to the PDF pages

                        </ListSubheader>
                        {/* 
                        <Button variant="outlined" color="primary" onClick={handleClickOpen} >

                            <EditIcon style={{ fontFamily: "Poppins, Helvetica, sans-serif", }} />
                        </Button> */}

                    </div>

                    // :
                    // <div>
                    //     <ListItem key={prov.id} button >
                    //         <ListItemAvatar>
                    //             <Avatar
                    //                 alt={prov.prov_name_text}
                    //                 src={prov.prov_cover_url}
                    //             />
                    //         </ListItemAvatar>
                    //         <ListItemText id={prov.id} primary={prov.prov_name_text} />
                    //         <ListItemSecondaryAction>
                    //             <DeleteDialog provd={prov} />
                    //         </ListItemSecondaryAction>
                    //     </ListItem>
                    // </div>
                }

                <Divider />
{/*                 
                <div>

                    {
                        contentData && contentData.provider_details ?
                            <PublisherListCard
                                showProvName={(status) => { UpdateProvStatus(status) }}
                                providerData={(provider) => { UpdateProvData(provider) }}
                                contentData={contentData.provider_details}
                                getContentDatafromPubList={(contData) => { sendContDatatoDashboard(contData, true) }}
                                selCont={selCont}
                                bookDetails={bookDetails}
                            />
                            :
                            <div style={{
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                marginTop: 20
                            }}>
                                
                                <Alert severity="warning">No content providers found!</Alert>
                            </div>

                    }

                </div> */}

                <div>
                    {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Open form dialog
                    </Button> */}

{/* 
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

                        <DialogTitle id="form-dialog-title">Content Provider List</DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Please select Content Provider
                            </DialogContentText>

                            <Card>
                                <CheckboxListSecondary
                                    savePubContProv={async (e, provId) => {
                                        await new Promise(async (resolve, reject) => {
                                            await savePubContProv(e, provId)
                                            resolve() // wait over
                                        })

                                        // fetch all content again
                                        dispatch(listEbookContentGet(book_id))
                                    }}
                                    ContentProviders={providers}
                                    // already mapped ..
                                    contentData={contentData.provider_details}
                                    getSelectedProv={(list) => {
                                        console.log("selected providers list", list)
                                    }} />


                            </Card>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Done
                            </Button>
                            
                        </DialogActions>
                    </Dialog> */}


                </div>
            </div>



            




                    {/* Map the chapters */}
                    {/* show chapters list here */}

                    <div style={{
                        display: 'flex',
                        marginTop: 20
                    }}>

                        <div
                            style={{
                                width: '100%'
                            }}>
                            
                                    <Typography>Map chapters to book</Typography>
                                

                                    {/* <div>
                            <Typography>
                                Map your book page numbers to the chapters.
                            </Typography>
                            </div> */}

                                    <div 
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',

                                    }}>
                                    <div
                                        style={{
                                            width: '100%'
                                        }}>

                                        {/* <>{(chapDetails && chapDetails.data) ? chapDetails.data.length : 0} is chap length</> */}
                                        {

                                            // (chapDetails && chapDetails.data && chapDetails.data.length) ?
                                                // chapDetails.data.map((chp, idx) => {
                                            (chapsData && chapsData.length) ?
                                                chapsData.map((chp, idx) => {
                                                    let chapEName = chapNames[idx];
                                                    let sPageNo = pageNos[idx];
                                                    let sPageENo = pageENos[idx];
                                                    let sTopENames = topNames[idx];
                                                    let sTopEIndices = topIndices[idx];
                                                    let sChapEIdx = chapIndices[idx];

                                                    const topsList = chp.topic_list;

                                                    // console.log('render sPageNo', sPageNo);
                                                    // console.log('render sPageENo', sPageENo);
                                                    
                                                    // if(!chapEName) 
                                                        // chapEName = chp.chapter_title;
                                                    return (
                                                        <>
                                                            
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"

                                                                >
                                                                <div
                                                                   style={{ width: '100%' }} >


                                                                    <div
                                                                        style={{ width: '100%', marginBottom: 20 }}
                                                                    >
                                                                        {chp.chapter_title}
                                                                    </div>

                                                                    <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between',
                                                                        width: '100%',
                                                                        marginBottom: 10
                                                                    }}>
                                                                        <div
                                                                            style={{ width: '75%' }}>
                                                                            <TextField
                                                                                key={idx}
                                                                                style={{ width: '100%' }}
                                                                                size="small"
                                                                                id="outlined-basic" label={chp.chapter_title} variant="outlined"
                                                                                defaultValue={''}
                                                                                value={chapEName}
                                                                                multiline={false}
                                                                                onChange={(e) => handleChapNameChange(e, idx)}
                                                                            />
                                                                        </div>

                                                                        <div
                                                                            style={{ width: '12%' }}>
                                                                            <TextField
                                                                                key={idx}
                                                                                size="small"
                                                                                id="outlined-basic" label="Index" variant="outlined"
                                                                                defaultValue={0}
                                                                                value={sChapEIdx}
                                                                                onChange={(e) => handleChapIndexChange(e, idx)}
                                                                            />
                                                                        </div>
                                                                        
                                                                        <div
                                                                            style={{ width: '22%' }}>
                                                                            <TextField
                                                                                key={idx}
                                                                                size="small"
                                                                                id="outlined-basic" label="Page Start" variant="outlined"
                                                                                defaultValue={0}
                                                                                value={sPageNo}
                                                                                type="number"
                                                                                onChange={(e) => handlePageNoChange(e, idx)}
                                                                            />
                                                                        </div>
                                                                        
                                                                        {/* <div
                                                                            style={{ width: '12%' }}>
                                                                            <TextField
                                                                                key={idx}
                                                                                size="small"
                                                                                id="outlined-basic" label="Page End" variant="outlined"
                                                                                defaultValue={0}
                                                                                value={sPageENo}
                                                                                onChange={(e) => handlePageENoChange(e, idx)}
                                                                            />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                    
                                                                </AccordionSummary>

                                                                    {/* topics */}
                                                                    
                                                                <AccordionDetails>
                                                                    <div
                                                                    style={{display:'flex',flexDirection:'column', width:'100%'}}>
                                                                    <h4>Topics</h4>
                                                                    {/* iterate the topics */}
                                                                    {
                                                                    // (sTopENames && sTopENames.length > 0) 
                                                                        // ?
                                                                        // sTopENames.map((tpName) => { // only map will return something
                                                                            // return  (
                                                                                <div
                                                                            style={{ width: '100%', marginTop: 20 }} >
                                                                                {(topsList && topsList.length) ?
                                                                                    topsList.map((top, tpIdx) => {
                                                                                        const { topic_id, topic_index, topic_title } = top;
                                                                                        let tpName = sTopENames?.length ? sTopENames[tpIdx] : '';
                                                                                        let tpIdx2 = sTopEIndices?.length ? sTopEIndices[tpIdx] : topic_index;
                                                                                        return (
                                                                                        <>
                                                                                            {/* <div
                                                                                                style={{ width: '100%', marginBottom: 20 }}
                                                                                            >
                                                                                                {topic_title}
                                                                                            </div> */}

                                                                                            <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row',
                                                                                                justifyContent: 'space-between',
                                                                                                width: '100%',
                                                                                                marginBottom: 10
                                                                                            }}>
                                                                                                <div
                                                                                                    style={{ width: '75%' }}>
                                                                                                    <TextField
                                                                                                        key={idx}
                                                                                                        style={{ width: '100%' }}
                                                                                                        size="small"
                                                                                                        id="outlined-basic" label={topic_title} variant="outlined"
                                                                                                        defaultValue={``}
                                                                                                        // placeholder={topic_title}
                                                                                                        value={tpName}
                                                                                                        multiline={false}
                                                                                                        onChange={(e) => handleTopNameChange(e, tpIdx, idx)}
                                                                                                    />
                                                                                                </div>

                                                                                                <div
                                                                                                    style={{ width: '22%' }}>
                                                                                                    <TextField
                                                                                                        key={idx}
                                                                                                        size="small"
                                                                                                        id="outlined-basic" label="Index" variant="outlined"
                                                                                                        defaultValue={0}
                                                                                                        value={tpIdx2}
                                                                                                        type="number"
                                                                                                        
                                                                                                        onChange={(e) => handleTopIndexChange(e, tpIdx, idx)}
                                                                                                    />
                                                                                                </div>
                                                                                                {/* <div
                                                                                                    style={{ width: '22%' }}>
                                                                                                    <TextField
                                                                                                        key={idx}
                                                                                                        size="small"
                                                                                                        id="outlined-basic" label="Page Start" variant="outlined"
                                                                                                        defaultValue={0}
                                                                                                        value={sPageNo}
                                                                                                        type="number"
                                                                                                        onChange={(e) => handlePageNoChange(e, idx)}
                                                                                                    />
                                                                                                </div> */}
                                                                                            </div>
                                                                                        </>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    <>
                                                                                        <Alert2>No topics found!</Alert2>
                                                                                    </>
                                                                                }
                                                                                </div>
                                                                            // )
                                                                        // })
                                                                        // :

                                                                        // <></>
                                                                    }
                                                                    </div>
                                                                    
                                                                </AccordionDetails>
                                                            </Accordion>

                                                        </>
                                                    );
                                                })

                                                :

                                                <>
                                                    <Alert severity="info">No Board or standard selected!</Alert>
                                                </>
                                        }


                                        {/*                                 

                                <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <div
                                    style={{}}
                                    >
                                        Chapter 2
                                    </div>

                                    <div>
                                        <TextField 
                                            size="small"
                                            id="outlined-basic" label="Page" variant="outlined" />
                                    </div>


                                </div> */}

                                    </div>
                                    
                                    <Button color="primary"
                                    variant="outlined"
                                    style={{
                                        align: 'right'
                                    }}
                                    onClick={() => {
                                        handleSaveMappingClicked()
                                    }}
                                    >
                                    {

                                        (isSaving) ?
                                        <><CircularProgress /></>
                                        :
                                        <>Save Mapping</>
                                    }    
                                        
                                        
                                    </Button>
                                    </div>

                                    

                                    <Snackbar
                                        open={open2}
                                        autoHideDuration={6000}
                                        onClose={handleClose2}
                                        message="Automated Content Generation completed successfully..."
                                        // action={action2}
                                    />

                        </div>

                    </div>







            <div style={{
                marginTop: 20
            }}>
                {
                    // teachPrev ?
                    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#fff" }}>
                        {/* <Link to={"/publisher-ebook/teach-preview/?book-id=" + bookId2}> */}
                        <a href={Constants.Application.PUSTAK_URL + "/books/read/" + bookId2} target="_blank">
                            <Button color="primary">Teach Preview</Button>
                        </a>
                        {/* </Link> */}
                    </div>
                    // :
                    // <></>
                }
            </div>


        </div>
    )
}

export default PublisherListSideBar;
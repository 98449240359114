/**
 * axios main configuration
 */

import axios from 'axios';
import Constants from '../resource/Constants';
// import UserSession from '../resource/UserSession';

export default axios.create({
    baseURL: Constants.Application.PLATFORM_END_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': 'Bearer' + UserSession.authtoken,

    }
})


// SITE_URL: 'https://pub.kgtopg.com.',
// // SITE_URL: 'http://localhost:3000',
// PLATFORM_END_URL: 'https://admin.kgtopg.com',
// // PLATFORM_END_URL: 'http://192.168.29.224/platform',
// PARTNER_END_URL: 'https://partner.kgtopg.com',
// // PARTNER_END_URL: 'http://192.168.29.224/partner',
// NODEJS_END_URL: 'https://api.kgtopg.com',
// // NODEJS_END_URL: 'http://192.168.29.224/nodeserver',
// PUSTAK_URL: 'https://books.kgtopg.com',
// // PUSTAK_URL: 'http://192.168.29.224/pustak',
// PUSTAK_BOOKS_URL: 'https://books.kgtopg.com/static/books',
// // PUSTAK_BOOKS_URL: 'http://192.168.29.224/pustak/static/books',
// // SPRING_API_URL: 'http://192.168.29.224:8080/examerspring',
// SPRING_API_URL: 'https://api2.classess.com',
// // PLATFORM_END_URL: 'http://192.168.10.117:8002',
// // PARTNER_END_URL: 'http://192.168.10.117:8004',
// // TEMP_END_URL: 'http://192.168.10.117:8003',
// // TEMP_END_URL1: 'http://192.168.10.117:8001',

/**
 * @project kgtopg - schooler - partner
 * curriculum map data services
 */
import API from "./http";
import axios from "axios";
// import qs from "querystring";
import Constants from "../resource/Constants";

export default {
    async postSaveMap(data) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            // url += "/curriculum-map/";
            url += "/save-map/";

            console.log("postSaveMap api url", url)

            const options = {
                method: "POST",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postSaveMap api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },
    async postUpdateMap(data, mapId) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/curriculum-map/${mapId}`;

            console.log("postUpdateMap api url", url)

            const options = {
                method: "PUT",
                data: data,
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("postUpdateMap api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },
    async deleteMap(mapId) {
        try {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "*/*",
            };

            let url = Constants.Application.PARTNER_END_URL;
            url += `/curriculum-map/${mapId}`;

            console.log("deleteMap api url", url)

            const options = {
                method: "DELETE",
                url,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    // 'Authorization': 'Bearer ' + UserSession.authtoken,
                },

            };

            let response = await axios(options);

            console.log("deleteMap api response", response)


            if (response) return response;
            else return false;
        } catch (ex) {
            return false;
        }
    },
    async getMaps(pubId) {
        // console.log("date",stuId,jsDate)
        // we wait
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/curriculum-map/get-pub-maps/${pubId}/`;

            console.log(`curriculum maps by publisher url ${url}`);

            let response = await axios.get(url);
            // console.log(`upcoming response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response)
                return response
            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    async getMapDetails(mapId) {
        // console.log("date",stuId,jsDate)
        // we wait
        try {

            let url = Constants.Application.PARTNER_END_URL;
            url += `/curriculum-map/${mapId}`;

            console.log(`curriculum map details view url ${url}`);

            let response = await axios.get(url);
            console.log(`curriculum map details response ${JSON.stringify(response)}`)
            // let response = await API.get(`/schooler/teacher-upcoming-classes-service?staffid=${staffId}`);

            // .then(response => {
            // console.log(`upcoming classes response ${JSON.stringify(response)}`)

            // let result = response.data.data
            // let message = response.data.message

            if (response)
                return response.data
            else
                return false
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    }

}